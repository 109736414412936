import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { formatTranslation } from '@utils/format-translation';
import { useSupport } from '@hooks/index';
import { ESupportType } from '@http/enums';

interface IUseShopProps {
  currentUserNickname?: string;
  userEmail?: string;
  shopEmail?: string;
  shopVKCommunityLink?: string;
  wantToBuyButtonType?: ESupportType;
}

export default function useShop({
  currentUserNickname,
  userEmail,
  shopEmail,
  shopVKCommunityLink,
  wantToBuyButtonType,
}: IUseShopProps) {
  const { t } = useTranslation();
  const shopEmailOptions = useMemo(
    () =>
      shopEmail
        ? {
            email: shopEmail,
            body: formatTranslation(t('event__market_email_text'), [
              currentUserNickname || '',
              userEmail || '',
            ]),
            subject: t('event__market_email_subject'),
          }
        : undefined,
    [shopEmail, currentUserNickname, userEmail, t],
  );

  const { handleSupportAction } = useSupport();

  const handleShopAction = useCallback(() => {
    handleSupportAction({
      type: wantToBuyButtonType,
      emailOptions: shopEmailOptions,
      vkLink: shopVKCommunityLink,
    });
  }, [wantToBuyButtonType, shopEmailOptions, shopVKCommunityLink, handleSupportAction]);

  return {
    handleShopAction,
  };
}
