import { achievementListReducer } from './slices/achievement-list';
import { avatarListReducer } from './slices/avatar-list';
import { calendarReducer } from './slices/calendar';
import { challengeDetailsReducer } from './slices/challenge-details';
import { challengeListReducer } from './slices/challenge-list';
import { eventDetailsReducer } from './slices/event-details';
import { eventListReducer } from './slices/event-list';
import { eventProjectListReducer } from './slices/event-project-list';
import { eventRatingReducer } from './slices/event-rating';
import { eventResultReducer } from './slices/event-result';
import { feedReducer } from './slices/feed';
import { followersReducer } from './slices/followers';
import { followingReducer } from './slices/following';
import { globalSearchReducer } from './slices/global-search';
import { hotnewsReducer } from './slices/hotnews';
import { identityReducer } from './slices/identity';
import { interactiveReducer } from './slices/interactive';
import { localizationReducer } from './slices/localization';
import { locationListReducer } from './slices/location-list';
import { navigationReducer } from './slices/navigation';
import { newsDetailsReducer } from './slices/news-details';
import { newsListReducer } from './slices/news-list';
import { notificationListReducer } from './slices/notification-list';
import { notificationStatusReducer } from './slices/notification-status';
import { profileReducer } from './slices/profile';
import { projectsReducer } from './slices/projects';
import { quizReducer } from './slices/quiz';
import { quizResultReducer } from './slices/quiz-result';
import { rubricListReducer } from './slices/rubric-list';
import { skillDetailsReducer } from './slices/skill-details';
import { skillListReducer } from './slices/skill-list';
import { userAchievementReducer } from './slices/user-achievement';
import { userFavoriteProjectsReducer } from './slices/user-favorite-projects-slice';
import { userListReducer } from './slices/user-list';
import { userProfileReducer } from './slices/user-profile';
import { userProjectListReducer } from './slices/user-project-list';
import { userSkillListReducer } from './slices/user-skill';
import { optionsReducer } from './slices/options';
import { createProjectReducer } from './slices/create-project';
import { accessRightsReducer } from './slices/access-rights';

export default {
  accessRights: accessRightsReducer,
  achievementList: achievementListReducer,
  avatarList: avatarListReducer,
  calendar: calendarReducer,
  challengeDetails: challengeDetailsReducer,
  challengeList: challengeListReducer,
  createProject: createProjectReducer,
  eventDetails: eventDetailsReducer,
  eventList: eventListReducer,
  eventProjectList: eventProjectListReducer,
  eventRating: eventRatingReducer,
  eventResult: eventResultReducer,
  feed: feedReducer,
  followers: followersReducer,
  following: followingReducer,
  globalSearch: globalSearchReducer,
  hotnews: hotnewsReducer,
  identity: identityReducer,
  interactive: interactiveReducer,
  localization: localizationReducer,
  locationList: locationListReducer,
  navigation: navigationReducer,
  newsDetails: newsDetailsReducer,
  newsList: newsListReducer,
  notificationList: notificationListReducer,
  notificationStatus: notificationStatusReducer,
  profile: profileReducer,
  projects: projectsReducer,
  quiz: quizReducer,
  quizResult: quizResultReducer,
  rubricList: rubricListReducer,
  skillDetails: skillDetailsReducer,
  skillList: skillListReducer,
  userAchievement: userAchievementReducer,
  userFavoriteProjects: userFavoriteProjectsReducer,
  userList: userListReducer,
  userProfile: userProfileReducer,
  userProjectList: userProjectListReducer,
  userSkillList: userSkillListReducer,
  options: optionsReducer,
};
