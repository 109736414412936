import { useEffect, useCallback } from 'react';

export default function useVersionChecker() {
  const fetchVersion = useCallback(async () => {
    try {
      const response = await fetch(`/meta.json?timestamp=${new Date().getTime()}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const { version, sprint } = data;

      if (sprint !== null) {
        const sprintNumber = localStorage.getItem('sprint');
        if (sprintNumber !== sprint) {
          localStorage.setItem('sprint', sprint);
        }
      }

      const localStorageVersion = localStorage.getItem('version');

      if (version !== null && version !== localStorageVersion) {
        localStorage.setItem('version', version);

        if (localStorageVersion !== null) {
          window.location.reload();
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error('There was a problem with the fetch operation:', error.message);
      } else {
        console.error('There was a problem with the fetch operation:', error);
      }
    }
  }, []);

  useEffect(() => {
    fetchVersion();
    const intervalId = setInterval(fetchVersion, 5 * 60 * 1000); // 5 минут

    return () => {
      clearInterval(intervalId);
    };
  }, [fetchVersion]);
}
