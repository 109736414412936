import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { ISkillItem } from '@http/models/skill-item';
import { v1 } from '@api/v1';
import { ELoadingStatus } from '../../http/enums';

interface UserSkillListState {
  error?: string;
  loading: ELoadingStatus;
  items: ISkillItem[];
}

const initialState: { [userId: string]: UserSkillListState } = {};

const createState = (): UserSkillListState => ({
  loading: ELoadingStatus.Idle,
  items: [],
});

export const fetchUserSkills = createAsyncThunk<
  { items: ISkillItem[]; userId: string },
  string,
  { state: RootState; rejectValue: string }
>('userSkillList/fetchSkills', async (userId, { rejectWithValue }) => {
  if (!userId) {
    return rejectWithValue('Invalid userId');
  }

  const response = await v1.user.skill.get(userId);

  if (response.errorCode) {
    return rejectWithValue(response.errorMsg || 'Failed to fetch skills');
  }

  return {
    items: response.items || [],
    userId,
  };
});

const slice = createSlice({
  name: 'userSkillList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserSkills.pending, (state, action) => {
        const userId = action.meta.arg;
        state[userId] = state[userId] ?? createState();
        state[userId].loading = ELoadingStatus.Loading;
        state[userId].error = undefined;
      })
      .addCase(fetchUserSkills.fulfilled, (state, action) => {
        const { userId, items } = action.payload;
        state[userId] = state[userId] ?? createState();
        state[userId].items = items;
        state[userId].loading = ELoadingStatus.Succeeded;
        state[userId].error = undefined;
      })
      .addCase(fetchUserSkills.rejected, (state, action) => {
        const userId = action.meta.arg;
        state[userId] = state[userId] ?? createState();
        state[userId].loading = ELoadingStatus.Failed;
        state[userId].error = action.payload;
      });
  },
});

const userSkillList = {
  ...slice.actions,
  selectLoading: (userId: string) => (state: RootState) => state.userSkillList[userId]?.loading,
  selectItems: (userId: string) => (state: RootState) => state.userSkillList[userId]?.items,
  selectError: (userId: string) => (state: RootState) => state.userSkillList[userId]?.error,
  loadData: fetchUserSkills,
};

export const userSkillListReducer = slice.reducer;
export default userSkillList;
