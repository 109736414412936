import { createSlice, createAsyncThunk, createSelector, PayloadAction } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';
import i18next from 'i18next';

import { RootState } from '../store';
import { IUserUpdates } from '@api/v1/user/user-updates';
import storage from '@storage/index';
import { IProfileUserBlocklist, UpdateProfileRequest } from '@api/v1/profile/profile';
import { IUserItem } from '@models/user-item';
import { v1 } from '@api/v1';
import { IDropdownItem } from '@api/v1/identity/identity';
import { ELoadingStatus } from '@http/enums';
import FormFile from '../../http/form-file';

// Интерфейс состояния профиля
interface IProfileState {
  error?: string;
  loading: ELoadingStatus;
  logout: boolean;
  updated: boolean;
  data: IUserItem;
}

// Начальное состояние
const initialState: IProfileState = {
  loading: ELoadingStatus.Idle,
  logout: false,
  updated: false,
  data: {},
};

// обновление аватара профиля
export const updateAvatar = createAsyncThunk<
  string, // возвращаем новый URL аватара (тип string)
  { blob: Blob; fileName: string; rawImage?: string },
  { rejectValue: string; state: RootState }
>('profile/updateAvatar', async ({ blob, fileName, rawImage }, { dispatch, rejectWithValue }) => {
  // Вызываем API для обновления аватара
  const response = await v1.user.avatar.post({ file: new FormFile(blob, fileName) });
  if (response.errorCode) {
    return rejectWithValue(response.errorMsg!);
  }
  if (rawImage) {
    window.URL.revokeObjectURL(rawImage);
  }
  // Обновляем список аватаров (если требуется)
  dispatch(storage.avatarList.loadData());
  // Если URL отсутствует, возвращаем ошибку, чтобы thunk гарантированно вернул string
  if (!response.url) {
    return rejectWithValue('Avatar URL is missing');
  }
  return response.url;
});

// 1. Асинхронный thunk для загрузки профиля
export const loadProfile = createAsyncThunk<IUserItem, void, { rejectValue: string }>(
  'profile/loadData',
  async (_, { rejectWithValue }) => {
    const response = await v1.profile.get();
    if (response.errorCode) {
      // Обработка различных кодов ошибок
      if (response.errorCode === 500) {
        return rejectWithValue(i18next.t('common__msg_error_request'));
      }
      if (response.errorCode === 15150) {
        return rejectWithValue(i18next.t('common__msg_error_need_to_continue_register'));
      }
      return rejectWithValue(response.errorMsg!);
    }
    return response;
  },
);

// 2. Асинхронный thunk для получения privacy list
export const loadPrivacyList = createAsyncThunk<IDropdownItem[], void, { rejectValue: string }>(
  'profile/loadPrivacyList',
  async (_, { rejectWithValue }) => {
    const response = await v1.identity.dropdown.get('SharingPermissions');
    if (response.errorCode) {
      return rejectWithValue(response.errorMsg!);
    }
    return response.items as IDropdownItem[];
  },
);

// 3. Асинхронный thunk для загрузки обновлений пользователя
export const loadUserUpdates = createAsyncThunk<IUserUpdates, void, { rejectValue: string }>(
  'profile/loadUserUpdates',
  async (_, { rejectWithValue }) => {
    const response = await v1.user.updates.get();
    if (response.errorCode) {
      return rejectWithValue(response.errorMsg!);
    }
    return response;
  },
);

// 4. Асинхронный thunk для обновления профиля
export const updateUserProfile = createAsyncThunk<
  IUserItem,
  { data: UpdateProfileRequest; withUpdate?: boolean },
  { rejectValue: string }
>('profile/updateUserProfile', async ({ data, withUpdate }, { rejectWithValue, dispatch }) => {
  const response = await v1.profile.put(data);
  if (response.errorCode) {
    const errorMsg =
      response.errorCode === 2090
        ? i18next.t('common__msg_error_nickname_already_exists')
        : response.errorMsg;
    return rejectWithValue(errorMsg!);
  }
  // Обновляем данные в других частях приложения
  dispatch(setData(data));
  dispatch(storage.identity.setNickname(response.userName));
  dispatch(setNickname(response.userName));
  if (withUpdate) {
    dispatch(setProfileUpdated(true));
  }
  return response;
});

// 5. Асинхронный thunk для загрузки блоклиста
export const loadBlocklist = createAsyncThunk<IProfileUserBlocklist, void, { rejectValue: string }>(
  'profile/loadBlocklist',
  async (_, { rejectWithValue }) => {
    const response = await v1.profile.blocklist.get();
    if (response.errorCode) {
      return rejectWithValue(response.errorMsg!);
    }
    return response;
  },
);

// Слайс с синхронными экшенами
const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    resetData: (state) => {
      state.data = {};
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setData: (state, action: PayloadAction<IUserItem>) => {
      const newData = { ...state.data, ...action.payload };
      if (!isEqual(newData, state.data)) {
        state.data = newData;
      }
    },
    setAvatarUrl: (state, action: PayloadAction<string | undefined>) => {
      state.data.avatarUrl = action.payload;
    },
    setNickname: (state, action: PayloadAction<string | undefined>) => {
      state.data.userName = action.payload;
    },
    setAbout: (state, action: PayloadAction<string | undefined>) => {
      state.data.about = action.payload;
    },
    setEmployeeFirstName: (state, action: PayloadAction<string | undefined>) => {
      state.data.employeeProfile = state.data.employeeProfile ?? {};
      state.data.employeeProfile.firstName = action.payload;
    },
    setEmployeeLastName: (state, action: PayloadAction<string | undefined>) => {
      state.data.employeeProfile = state.data.employeeProfile ?? {};
      state.data.employeeProfile.lastName = action.payload;
    },
    setEmployeeEducationalInstitution: (state, action: PayloadAction<string | undefined>) => {
      state.data.employeeProfile = state.data.employeeProfile ?? {};
      state.data.employeeProfile.educationalInstitution = action.payload;
    },
    setReferenceVkontakte: (state, action: PayloadAction<string | undefined>) => {
      state.data.reference = state.data.reference ?? {};
      state.data.reference.vkontakte = action.payload;
    },
    setReferenceOdnoklassniki: (state, action: PayloadAction<string | undefined>) => {
      state.data.reference = state.data.reference ?? {};
      state.data.reference.odnoklassniki = action.payload;
    },
    setUserUpdates: (state, action: PayloadAction<IUserUpdates>) => {
      state.data.userUpdates = action.payload;
    },
    setProfileUpdated: (state, action: PayloadAction<boolean>) => {
      state.updated = action.payload;
    },
    setBLocklistLoading: (state, action: PayloadAction<ELoadingStatus>) => {
      state.data.blocklist = state.data.blocklist ?? {};
      state.data.blocklist.loading = action.payload;
    },
    setBlocklistUsers: (state, action: PayloadAction<IProfileUserBlocklist>) => {
      state.data.blocklist = action.payload;
    },
    setPrivacyList: (state, action: PayloadAction<IDropdownItem[]>) => {
      state.data.privacyList = action.payload;
    },
    removeUserFromBlocklist: (state, action: PayloadAction<string>) => {
      state.data.blocklist = state.data.blocklist ?? {};
      state.data.blocklist.items =
        state.data.blocklist.items?.filter((i: any) => i.nickName !== action.payload) || [];
    },
  },
  extraReducers: (builder) => {
    // Обработка loadProfile
    builder
      .addCase(loadProfile.pending, (state) => {
        state.loading = ELoadingStatus.Loading;
        state.error = undefined;
      })
      .addCase(loadProfile.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = ELoadingStatus.Succeeded;
      })
      .addCase(loadProfile.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = ELoadingStatus.Failed;
      });

    // Обработка loadPrivacyList
    builder
      .addCase(loadPrivacyList.pending, (state) => {
        // Можно установить loading для privacyList, если требуется
      })
      .addCase(loadPrivacyList.fulfilled, (state, action) => {
        state.data.privacyList = action.payload;
      })
      .addCase(loadPrivacyList.rejected, (state, action) => {
        state.error = action.payload as string;
      });

    // Обработка loadUserUpdates
    builder
      .addCase(loadUserUpdates.pending, (state) => {
        // Здесь можно не менять глобальный loading, если обновления загружаются отдельно
      })
      .addCase(loadUserUpdates.fulfilled, (state, action) => {
        state.data.userUpdates = action.payload;
      })
      .addCase(loadUserUpdates.rejected, (state, action) => {
        // Можно сохранить ошибку или логировать её
      });

    // Обработка updateUserProfile
    builder
      .addCase(updateUserProfile.pending, (state) => {
        state.loading = ELoadingStatus.Loading;
        state.error = undefined;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = ELoadingStatus.Succeeded;
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = ELoadingStatus.Failed;
      });

    // Обработка loadBlocklist
    builder
      .addCase(loadBlocklist.pending, (state) => {
        if (!state.data.blocklist) {
          state.data.blocklist = {} as any;
        }
        state.data.blocklist!.loading = ELoadingStatus.Loading;
      })
      .addCase(loadBlocklist.fulfilled, (state, action) => {
        state.data.blocklist = { ...action.payload, loading: ELoadingStatus.Succeeded };
      })
      .addCase(loadBlocklist.rejected, (state, action) => {
        state.error = action.payload as string;
        if (state.data.blocklist) {
          state.data.blocklist.loading = ELoadingStatus.Failed;
        }
      });

    // Обработка Обновления аватара
    builder
      .addCase(updateAvatar.pending, (state) => {
        state.loading = ELoadingStatus.Loading;
        state.error = undefined;
      })
      .addCase(updateAvatar.fulfilled, (state, action) => {
        state.data.avatarUrl = action.payload;
        state.loading = ELoadingStatus.Succeeded;
      })
      .addCase(updateAvatar.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = ELoadingStatus.Failed;
      });
  },
});

// Экспортируем синхронные экшены
export const {
  resetData,
  setError,
  setData,
  setAvatarUrl,
  setNickname,
  setAbout,
  setEmployeeFirstName,
  setEmployeeLastName,
  setEmployeeEducationalInstitution,
  setReferenceVkontakte,
  setReferenceOdnoklassniki,
  setUserUpdates,
  setProfileUpdated,
  setBLocklistLoading,
  setBlocklistUsers,
  setPrivacyList,
} = slice.actions;

const profile = {
  ...slice.actions,
  selectProfileUpdated: (state: RootState) => state.profile.updated,
  selectError: (state: RootState) => state.profile.error,
  selectLoading: (state: RootState) => state.profile.loading,
  selectData: createSelector(
    (state: RootState) => state.profile,
    (profileState) => profileState.data,
  ),
  selectAvatarUrl: (state: RootState) => state.profile.data.avatarUrl,
  selectUserName: (state: RootState) => state.profile.data.userName,
  selectUserId: (state: RootState) => state.profile.data.id,
  selectUserEmail: (state: RootState) => state.profile.data.email,
  selectBlocklistItems: (state: RootState) => state.profile.data.blocklist?.items,
  selectUserAccessRights: (state: RootState) => state.profile.data.accessRights,
  getPrivacyList: loadPrivacyList,
  loadData: loadProfile,
  loadUserUpdates: loadUserUpdates,
  updateUserProfile: updateUserProfile,
  loadBlocklist: loadBlocklist,
  updateAvatar: updateAvatar,
};

export const profileReducer = slice.reducer;
export default profile;
