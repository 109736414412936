import { normalizeUrl } from './normalizeUrl';

export const openExternalLink = (url: string) => {
  const link = document.createElement('a');

  link.href = normalizeUrl(url);
  link.target = '_blank';
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );
};
