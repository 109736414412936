import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { EEventUserRatingType, ELoadingStatus, EEventType } from '@http/enums';
import { IEventRatingResponse } from '@http/api/v1/event/event-rating';
import { v1 } from '@api/v1';

interface IEventRatingState {
  loading: ELoadingStatus;
  error?: string;
  data: IEventRatingResponse;
  type: EEventUserRatingType;
}

const initialState: { [p: string]: IEventRatingState } = {};

const createState = (): IEventRatingState => ({
  loading: ELoadingStatus.Idle,
  data: {},
  type: EEventUserRatingType.Default,
});

export const loadData = createAsyncThunk<
  { id: number; data: IEventRatingResponse; type: EEventUserRatingType },
  { id: number; type?: EEventUserRatingType; take?: number },
  { rejectValue: string }
>(
  'eventRating/loadData',
  async ({ id, type = EEventUserRatingType.Default, take }, { rejectWithValue }) => {
    try {
      const response = await v1.event.id.rating.get(id, {
        userRatingType: type,
        take: take ?? (type === EEventUserRatingType.Top100 ? 100 : 25),
      });

      if (response.errorCode) {
        return rejectWithValue(response.errorMsg!);
      }

      return { id, data: response, type };
    } catch (error: any) {
      return rejectWithValue(error.message || 'Ошибка загрузки рейтинга события');
    }
  },
);

const slice = createSlice({
  name: 'eventRating',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<{ id: number; loading: ELoadingStatus }>) => {
      state[action.payload.id] = state[action.payload.id] ?? createState();
      state[action.payload.id].loading = action.payload.loading;
    },
    setError: (state, action: PayloadAction<{ id: number; error?: string }>) => {
      state[action.payload.id] = state[action.payload.id] ?? createState();
      state[action.payload.id].error = action.payload.error;
    },
    setData: (state, action: PayloadAction<{ id: number; data?: IEventRatingResponse }>) => {
      state[action.payload.id] = state[action.payload.id] ?? createState();
      state[action.payload.id].data = action.payload.data ?? {};
    },
    setType: (state, action: PayloadAction<{ id: number; type: EEventUserRatingType }>) => {
      state[action.payload.id] = state[action.payload.id] ?? createState();
      state[action.payload.id].type = action.payload.type;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadData.pending, (state, action) => {
        const { id } = action.meta.arg;
        state[id] = state[id] ?? createState();
        state[id].loading = ELoadingStatus.Loading;
        state[id].error = undefined;
      })
      .addCase(loadData.fulfilled, (state, action) => {
        const { id, data, type } = action.payload;
        state[id].data = data;
        state[id].type = type;
        state[id].loading = ELoadingStatus.Succeeded;
      })
      .addCase(loadData.rejected, (state, action) => {
        const { id } = action.meta.arg;
        state[id] = state[id] ?? createState();
        state[id].error = action.payload as string;
        state[id].loading = ELoadingStatus.Failed;
      });
  },
});

const eventRating = {
  ...slice.actions,
  selectError: (id: number) => (state: RootState) => state.eventRating[id]?.error,
  selectLoading: (id: number) => (state: RootState) => state.eventRating[id]?.loading,
  selectData: (id: number) => (state: RootState) => state.eventRating[id]?.data ?? {},
  selectAllData: () => (state: RootState) => state.eventRating ?? {},
  selectEventType: (id: number) => (state: RootState) =>
    state.eventRating[id]?.data?.type ?? EEventType.Festival,
  selectCurrentUserRating: (id: number) => (state: RootState) =>
    state.eventRating[id]?.data.currentUserRating,
  selectItems: (id: number, type: EEventUserRatingType) => (state: RootState) => {
    switch (type) {
      case EEventUserRatingType.Subdivision:
        return state.eventRating[id]?.data.subdivisionUserRatings;
      case EEventUserRatingType.Subdivision1:
        return state.eventRating[id]?.data.subdivision1UserRatings;
      case EEventUserRatingType.Location:
        return state.eventRating[id]?.data.locationUserRatings;
      case EEventUserRatingType.Top100:
        return state.eventRating[id]?.data.top100UserRatings;
      case EEventUserRatingType.General:
        return state.eventRating[id]?.data.generalUserRatings;
      case EEventUserRatingType.School:
        return state.eventRating[id]?.data.schoolUserRatings;
      case EEventUserRatingType.College:
        return state.eventRating[id]?.data.collegeUserRatings;
      case EEventUserRatingType.University:
        return state.eventRating[id]?.data.universityUserRatings;
      default:
        return [];
    }
  },
  loadData,
};

export const eventRatingReducer = slice.reducer;
export default eventRating;
