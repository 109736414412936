import { useEffect } from 'react';

export default function useReloadOnce(name: string) {
  useEffect(() => {
    if (!sessionStorage.getItem(name)) {
      sessionStorage.setItem(name, 'true');
      window.location.reload();
    }
  }, [name]);
}
