import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { IProjectItem } from '@http/models/project-item';
import { v1 } from '@api/v1';
import { ELoadingStatus } from '../../http/enums';

interface IEventProjectListState {
  loading: ELoadingStatus;
  error?: string;
  ids: number[];
  total: number;
}

const initialState: { [p: string]: IEventProjectListState } = {};

const createState = (): IEventProjectListState => ({
  loading: ELoadingStatus.Idle,
  ids: [],
  total: 0,
});

const slice = createSlice({
  name: 'eventProjectList',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<{ id: number; loading: ELoadingStatus }>) => {
      state[action.payload.id] = state[action.payload.id] ?? createState();
      state[action.payload.id].loading = action.payload.loading;
    },
    setError: (state, action: PayloadAction<{ id: number; error?: string }>) => {
      state[action.payload.id] = state[action.payload.id] ?? createState();
      state[action.payload.id].error = action.payload.error;
    },
    setIds: (state, action: PayloadAction<{ id: number; ids?: number[] }>) => {
      state[action.payload.id] = state[action.payload.id] ?? createState();
      state[action.payload.id].ids = action.payload.ids ?? [];
    },
    setTotal: (state, action: PayloadAction<{ id: number; total?: number }>) => {
      state[action.payload.id] = state[action.payload.id] ?? createState();
      state[action.payload.id].total = action.payload.total ?? 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadData.pending, (state, action) => {
        const id = action.meta.arg.id;
        state[id] = state[id] ?? createState();
        state[id].loading = ELoadingStatus.Loading;
        state[id].error = undefined;
      })
      .addCase(loadData.fulfilled, (state, action) => {
        const { id, ids, total } = action.payload;
        state[id].ids = ids;
        state[id].total = total;
        state[id].loading = ELoadingStatus.Succeeded;
      })
      .addCase(loadData.rejected, (state, action) => {
        const id = action.meta.arg.id;
        state[id] = state[id] ?? createState();
        state[id].error = action.payload as string;
        state[id].loading = ELoadingStatus.Failed;
      });
  },
});

const getEventIds = async (id: number) => {
  const response = await v1.event.id.get(id);

  if (response.errorCode) {
    return [id];
  }

  return response.errorCode
    ? [id]
    : [...(response?.events?.map((event: any) => event.id) || []), id];
};

export const loadData = createAsyncThunk<
  { id: number; ids: number[]; items: IProjectItem[]; total: number },
  { id: number; page?: number },
  { rejectValue: string }
>('eventProjectList/loadData', async ({ id, page = 1 }, { rejectWithValue }) => {
  try {
    const eventIds = await getEventIds(id);
    const response = await v1.project.get({
      eventId: eventIds,
      medias: { fetch: true },
      skills: { fetch: true },
      take: 25,
      page,
    });

    if (response.errorCode) {
      return rejectWithValue(response.errorMsg!);
    }

    const ids = response.items.map((item: IProjectItem) => item.id);

    return { id, ids, items: response.items, total: response.total };
  } catch (error: any) {
    return rejectWithValue(error.message || 'Ошибка загрузки проектов события');
  }
});

const eventProjectList = {
  ...slice.actions,
  selectError: (id: number) => (state: RootState) => state.eventProjectList[id]?.error,
  selectLoading: (id: number) => (state: RootState) => state.eventProjectList[id]?.loading,
  selectItems: (id: number) => (state: RootState) =>
    state.eventProjectList[id]?.ids.map((id) => state.projects[id]).filter(Boolean),
  selectTotal: (id: number) => (state: RootState) => state.eventProjectList[id]?.total,
  loadData,
};

export const eventProjectListReducer = slice.reducer;
export default eventProjectList;
