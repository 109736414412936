import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { INotificationStatus } from '@http/models/notification-status';
import { v1 } from '@api/v1';
import { ELoadingStatus } from '../../http/enums';

interface INotificationStatusState {
  loading: ELoadingStatus;
  status: INotificationStatus;
  error?: string;
}

const initialState: INotificationStatusState = {
  loading: ELoadingStatus.Idle,
  status: {
    newNotifications: 0,
    newNews: 0,
    newAchievements: 0,
  },
};

const slice = createSlice({
  name: 'notificationStatus',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<INotificationStatus>) => {
      state.status = { ...state.status, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadStatus.pending, (state) => {
        state.loading = ELoadingStatus.Loading;
        state.error = undefined;
      })
      .addCase(loadStatus.fulfilled, (state, action) => {
        state.loading = ELoadingStatus.Succeeded;
        state.status = { ...state.status, ...action.payload };
      })
      .addCase(loadStatus.rejected, (state, action) => {
        state.loading = ELoadingStatus.Failed;
        state.error = action.payload as string;
      });
  },
});

export const loadStatus = createAsyncThunk(
  'notificationStatus/loadStatus',
  async (_, { rejectWithValue }) => {
    try {
      const response = await v1.notification.status.get();
      if (response.errorCode) {
        return rejectWithValue(response.errorMsg);
      }
      return response as INotificationStatus;
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : String(error);
      return rejectWithValue(errorMessage);
    }
  },
);

const notificationStatus = {
  ...slice.actions,
  loadStatus,
  selectLoading: (state: RootState) => state.notificationStatus.loading,
  selectError: (state: RootState) => state.notificationStatus.error,
  selectStatus: (state: RootState) => state.notificationStatus.status,
};

export const notificationStatusReducer = slice.reducer;
export default notificationStatus;
