import { useContext } from 'react';
import { PopupContext } from '@providers/PopupProvider';

export default function usePopup() {
  const context = useContext(PopupContext);

  if (!context) {
    throw new Error('usePopup must be used within a PopupProvider');
  }

  const { showPopup, hidePopup, updatePopup } = context;

  return {
    showPopup,
    hidePopup,
    updatePopup,
  };
}
