import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { EventResultResponse } from '@api/v1/event/event-result';
import { EBannerType, ELoadingStatus } from '@http/enums';
import { v1 } from '@api/v1';

interface IEventResultState {
  loading: ELoadingStatus;
  error?: string;
  data: EventResultResponse;
}

const initialState: { [p: string]: IEventResultState } = {};

const createState = (): IEventResultState => ({
  loading: ELoadingStatus.Idle,
  data: {},
});

export const loadData = createAsyncThunk<
  { id: number; data: EventResultResponse },
  number,
  { rejectValue: string }
>('eventResult/loadData', async (id, { rejectWithValue }) => {
  try {
    const response: EventResultResponse = await v1.event.id.result.get(id);
    if (response.errorCode) {
      return rejectWithValue(response.errorMsg!);
    }
    return { id, data: response };
  } catch (error: any) {
    return rejectWithValue(error.message || 'Ошибка загрузки результатов события');
  }
});

const slice = createSlice({
  name: 'eventResult',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadData.pending, (state, action) => {
        const id = action.meta.arg;
        state[id] = state[id] ?? createState();
        state[id].loading = ELoadingStatus.Loading;
        state[id].error = undefined;
      })
      .addCase(loadData.fulfilled, (state, action) => {
        const { id, data } = action.payload;
        state[id] = state[id] ?? createState();
        state[id].data = data;
        state[id].loading = ELoadingStatus.Succeeded;
      })
      .addCase(loadData.rejected, (state, action) => {
        const id = action.meta.arg;
        state[id] = state[id] ?? createState();
        state[id].error = action.payload as string;
        state[id].loading = ELoadingStatus.Failed;
      });
  },
});

const eventResult = {
  ...slice.actions,
  selectError: (id: number) => (state: RootState) => state.eventResult[id]?.error,
  selectLoading: (id: number) => (state: RootState) => state.eventResult[id]?.loading,
  selectData: (id: number) => (state: RootState) => state.eventResult[id]?.data ?? {},
  selectMainBanner: (id: number) => (state: RootState) =>
    state.eventResult[id]?.data?.banners?.find((x) => x.type === EBannerType.Main) ?? {},
  selectResultBanner: (id: number) => (state: RootState) =>
    state.eventResult[id]?.data?.banners?.find((x) => x.isEventResultBanner) ?? {},
  loadData,
};

export const eventResultReducer = slice.reducer;
export default eventResult;
