import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import storage from '@storage/index';
import { url } from '@constants/url';

export default function useNavigateToProfile() {
  const navigate = useNavigate();
  const isAuthorized = useSelector(storage.identity.selectAuthorized);

  const navigateToProfile = (username?: string) =>
    !isAuthorized
      ? navigate(url().authPage)
      : username
      ? navigate(`/profile/${username}`)
      : navigate(-1);

  return navigateToProfile;
}
