export const copyText = async (copiedString: string) => {
  try {
    await navigator.clipboard.writeText(copiedString);

    return true;
  } catch (error) {
    if (error instanceof Error) console.error(error.message);
    
    return false;
  }
};
