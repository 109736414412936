import useQueryParams from './useQueryParams';
import { useCallback } from 'react';

export default function useQueryParam(
  name: string,
  defaultValue: string = '',
): [string, (value: any) => void] {
  const [params, setParams] = useQueryParams();

  const setParam = useCallback(
    (value: any) => {
      const newParams = {
        ...params,
        ...{ ...params.filters },
      };

      if (value || value <= 0) {
        newParams.filters[name] = value.toString();
      } else {
        delete newParams.filters[name];
      }

      setParams(newParams);
    },
    [name, params, setParams],
  );

  return [params.filters[name] ?? defaultValue, setParam];
}
