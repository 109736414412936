import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IEventItem } from '@http/models/event-item';
import { v1 } from '@api/v1';
import { ELoadingStatus } from '../../http/enums';

interface IEventListState {
  loading: ELoadingStatus;
  items: IEventItem[];
  error?: string;
}

const initialState: IEventListState = {
  loading: ELoadingStatus.Idle,
  items: [],
};

export const loadData = createAsyncThunk<
  IEventItem[], // Успешный результат
  number, // Параметры запроса
  { rejectValue: string } // Ошибка
>('eventList/loadData', async (storeId, { rejectWithValue }) => {
  try {
    const response = await v1.event.get({ banners: { fetch: true } });

    if (response.errorCode) {
      return rejectWithValue(response.errorMsg!);
    }

    let items = response.items ?? [];
    const shop = items.find((x: any) => x.id?.toString() === storeId);
    if (shop) {
      const newItems = [shop];
      for (let i = 0; i < items.length; i++) {
        if (items[i] && items[i] !== shop) {
          newItems.push(items[i]);
        }
      }
      items = newItems;
    }

    return items;
  } catch (error: any) {
    return rejectWithValue(error.message || 'Ошибка загрузки списка событий');
  }
});

const slice = createSlice({
  name: 'eventList',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<ELoadingStatus>) => {
      state.loading = action.payload;
    },
    setItems: (state, action: PayloadAction<IEventItem[]>) => {
      state.items = action.payload;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadData.pending, (state) => {
        state.loading = ELoadingStatus.Loading;
        state.error = undefined;
      })
      .addCase(loadData.fulfilled, (state, action) => {
        state.items = action.payload;
        state.loading = ELoadingStatus.Succeeded;
      })
      .addCase(loadData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = ELoadingStatus.Failed;
      });
  },
});

const eventList = {
  ...slice.actions,
  selectLoading: (state: RootState) => state.eventList.loading,
  selectItems: (state: RootState) => state.eventList.items,
  selectError: (state: RootState) => state.eventList.error,
  loadData,
};

export const eventListReducer = slice.reducer;
export default eventList;
