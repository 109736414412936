import React from 'react';
import cn from 'classnames/bind';

import s from './spacing.module.scss';
const cx = cn.bind(s);

interface SpacingProps {
  size:
    | 'h4'
    | 'h8'
    | 'h12'
    | 'h16'
    | 'h20'
    | 'h22'
    | 'h24'
    | 'h28'
    | 'h30'
    | 'h32'
    | 'h50'
    | 'h64'
    | 'fill';
}

export default function Spacing(props: SpacingProps) {
  return (
    <div
      className={cx(s.container, {
        [s[props.size]]: !!props.size,
      })}
    />
  );
}
