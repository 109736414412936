import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18next from 'i18next';

import { RootState } from '../store';
import { IProjectItem } from '@http/models/project-item';
import { v1 } from '@api/v1';
import { ELoadingStatus } from '../../http/enums';

interface IUserProjectListState {
  error?: string;
  loading: ELoadingStatus;
  items: IProjectItem[];
  total: number;
  page: number;
}

const initialState: { [userId: string]: IUserProjectListState } = {};

const getUserState = (state: typeof initialState, userId: string): IUserProjectListState => {
  if (!state[userId]) {
    state[userId] = { loading: ELoadingStatus.Idle, items: [], total: 0, page: 1 };
  }
  return state[userId];
};

export const loadUserProjects = createAsyncThunk<
  { items?: IProjectItem[]; total: number; userId: string },
  { userId: string; loadMore?: boolean },
  { state: RootState; rejectValue: string }
>('userProjectList/loadItems', async ({ userId, loadMore }, { getState, rejectWithValue }) => {
  const userState = getState().userProjectList[userId] ?? { page: 1 };

  const response = await v1.project.get({
    author: { id: [userId] },
    medias: { fetch: true },
    page: loadMore ? userState.page + 1 : 1,
    take: 20,
  });

  if (response.errorCode) {
    const errorMessage =
      response.errorCode === 500 ? i18next.t('common__msg_error_request') : response.errorMsg;
    return rejectWithValue(errorMessage!);
  }

  return {
    items: response.items || [],
    total: response.total ?? 0,
    userId,
  };
});

const slice = createSlice({
  name: 'userProjectList',
  initialState,
  reducers: {
    setImageSize: (
      state,
      action: PayloadAction<{ userId: string; projectId: number; width: number; height: number }>,
    ) => {
      const userState = getUserState(state, action.payload.userId);
      const project = userState.items.find((item) => item.id === action.payload.projectId);
      if (project) {
        project.previewImageWidth = action.payload.width;
        project.previewImageHeight = action.payload.height;
      }
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadUserProjects.pending, (state, action) => {
        const userId = action.meta.arg.userId;
        const userState = getUserState(state, userId);
        userState.loading = ELoadingStatus.Loading;
        userState.error = undefined;
      })
      .addCase(loadUserProjects.fulfilled, (state, action) => {
        const { userId, items, total } = action.payload;
        const userState = getUserState(state, userId);
        const loadMore = action.meta.arg.loadMore;

        const safeItems: IProjectItem[] = items || [];
        userState.items = loadMore ? [...userState.items, ...safeItems] : safeItems;
        userState.total = total;
        userState.page = loadMore ? userState.page + 1 : 1;
        userState.loading = ELoadingStatus.Succeeded;
      })
      .addCase(loadUserProjects.rejected, (state, action) => {
        const userId = action.meta.arg.userId;
        const userState = getUserState(state, userId);
        userState.loading = ELoadingStatus.Failed;
        userState.error = action.payload;
      });
  },
});

const userProjectList = {
  ...slice.actions,
  loadItems: loadUserProjects,
  selectLoading: (userId: string) => (state: RootState) => state.userProjectList[userId]?.loading,
  selectError: (userId: string) => (state: RootState) => state.userProjectList[userId]?.error,
  selectItems: (userId: string) => (state: RootState) => state.userProjectList[userId]?.items ?? [],
  selectTotal: (userId: string) => (state: RootState) => state.userProjectList[userId]?.total ?? 0,
};

export const userProjectListReducer = slice.reducer;
export default userProjectList;
