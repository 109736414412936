import { useEffect, useMemo, useState } from 'react';

/**
 * Хук обратного отчета
 * @param targetDate - целевая дата, до которой будет идти отчет
 */
const useCountdown = (targetDate: number | string | Date) => {
  const [countDown, setCountDown] = useState(0);

  const countDownDate = useMemo(() => new Date(targetDate).getTime(), [targetDate]);

  useEffect(() => {
    const countDown = countDownDate - new Date().getTime();
    if (countDown > 0) {
      setCountDown(countDownDate - new Date().getTime());
    }
  }, [countDownDate]);

  useEffect(() => {
    const interval = setInterval(() => {
      const countDown = countDownDate - new Date().getTime();
      if (countDown > 0) {
        setCountDown(countDownDate - new Date().getTime());
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown: number) => {
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return {
    /**
     * Дни
     */
    days,

    /**
     * Часы
     */
    hours,

    /**
     * Минуты
     */
    minutes,

    /**
     * Секунды
     */
    seconds,
  };
};

export default useCountdown;
