import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty, uniqBy } from 'lodash';

import { RootState } from '../store';
import { IRubricItem } from '@http/models/rubric-item';
import { IChallengeItem } from '@http/models/challenge-item';
import { ChallengeListResponse } from '@http/api/v1/challenge/challenge';
import { v1 } from '@api/v1';
import { ELoadingStatus } from '../../http/enums';

interface ILoadChallengeListRequest {
  rubricList?: IRubricItem[];
  loadmore?: boolean;
}
interface IChallengeListState {
  loading: ELoadingStatus;
  items: IChallengeItem[];
  scrollPosition: number;
  total: number;
  page: number;
  take: number;
  error?: string;
}

const initialState: IChallengeListState = {
  loading: ELoadingStatus.Idle,
  items: [],
  scrollPosition: 0,
  total: 0,
  page: 1,
  take: 25,
};

export const loadData = createAsyncThunk<
  { items: IChallengeItem[]; total: number; loadmore: boolean },
  ILoadChallengeListRequest | undefined,
  { state: RootState; rejectValue: string }
>('challengeList/loadData', async (request = {}, { getState, rejectWithValue }) => {
  const { rubricList, loadmore = false } = request;
  const state = getState();
  const rubricsId = isEmpty(rubricList) ? [] : rubricList?.map((i: IRubricItem) => i.id);

  try {
    const response: ChallengeListResponse = await v1.challenge.published.get({
      skills: { fetch: true },
      rubrics: { id: rubricsId, fetch: true },
      attributes: { fetch: true },
      page: loadmore ? state.challengeList.page + 1 : 1,
      take: 20,
    });

    if (response.errorCode) {
      return rejectWithValue(response.errorMsg!);
    }

    return { items: response.items, total: response.total, loadmore };
  } catch (error: any) {
    return rejectWithValue(error.message || 'Ошибка загрузки');
  }
});

const slice = createSlice({
  name: 'challengeList',
  initialState,
  reducers: {
    setScrollPosition: (state, action: PayloadAction<number>) => {
      state.scrollPosition = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadData.pending, (state) => {
        state.loading = ELoadingStatus.Loading;
        state.error = undefined;
      })
      .addCase(loadData.fulfilled, (state, action) => {
        const { items, total, loadmore } = action.payload;

        state.items = loadmore ? uniqBy([...state.items, ...items], 'id') : items;
        state.total = total;
        state.page = loadmore ? state.page + 1 : 1;
        state.loading = ELoadingStatus.Succeeded;
      })
      .addCase(loadData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = ELoadingStatus.Failed;
      });
  },
});

const challengeList = {
  ...slice.actions,
  selectLoading: (state: RootState) => state.challengeList.loading,
  selectItems: (state: RootState) => state.challengeList.items,
  selectScrollPosition: (state: RootState) => state.challengeList.scrollPosition,
  selectError: (state: RootState) => state.challengeList.error,
  selectPage: (state: RootState) => state.challengeList.page,
  selectTotal: (state: RootState) => state.challengeList.total,
  selectTake: (state: RootState) => state.challengeList.take,
  loadData,
};

export const challengeListReducer = slice.reducer;
export default challengeList;
