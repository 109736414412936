import { useCallback } from 'react';
import { IShareData } from '@models/share-data';

export default function useShare() {
  return useCallback((shareData: IShareData) => {
    if (navigator.share) {
      navigator
        .share(shareData)
        .then(() => console.log('Page shared successfully'))
        .catch((error) => console.error('Error sharing page:', error));
    } else {
      console.warn('Web Share API not supported');
    }
  }, []);
}
