import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';

import { RootState } from '../store';
import { NewsItem } from '@api/v0_1/news/news';
import { v0_1 } from '@api/v0_1';
import { ELoadingStatus } from '../../http/enums';

interface INewsListState {
  error?: string;
  items: NewsItem[];
  loading: ELoadingStatus;
  page: number;
  scrollPosition: number;
  total: number;
}

const initialState: INewsListState = {
  items: [],
  loading: ELoadingStatus.Idle,
  page: 1,
  scrollPosition: 0,
  total: 0,
};

const slice = createSlice({
  name: 'newsList',
  initialState,
  reducers: {
    setScrollPosition: (state, action: PayloadAction<number>) => {
      state.scrollPosition = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadData.pending, (state) => {
        state.loading = ELoadingStatus.Loading;
        state.error = undefined;
      })
      .addCase(loadData.fulfilled, (state, action) => {
        const { items, total, page, loadMore } = action.payload;

        state.items = loadMore ? uniqBy([...state.items, ...items], 'date') : items;
        state.total = total;
        state.page = page;
        state.loading = ELoadingStatus.Succeeded;
      })
      .addCase(loadData.rejected, (state, action) => {
        state.loading = ELoadingStatus.Failed;
        state.error = action.payload as string;
      });
  },
});

export const loadData = createAsyncThunk(
  'newsList/loadData',
  async (loadMore: boolean | undefined, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const page = loadMore ? state.newsList.page + 1 : 1;

    try {
      const response = await v0_1.news.get({
        page,
        take: 20,
      });

      if (response.errorCode) {
        return rejectWithValue(response.errorMsg);
      }

      return {
        items: response.items ?? [],
        total: response.total ?? 0,
        page,
        loadMore,
      };
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : String(error);
      return rejectWithValue(errorMessage);
    }
  },
);

const newsList = {
  ...slice.actions,
  loadData,
  selectLoading: (state: RootState) => state.newsList.loading,
  selectItems: (state: RootState) => state.newsList.items,
  selectError: (state: RootState) => state.newsList.error,
  selectTotal: (state: RootState) => state.newsList.total,
  selectScrollPosition: (state: RootState) => state.newsList.scrollPosition,
};

export const newsListReducer = slice.reducer;
export default newsList;
