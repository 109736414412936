import { getMailtoLink } from '@utils/getMailtoLink';

interface IEmailOptions {
  email: string;
  body?: string;
  subject?: string;
}

export const openEmailApp = (options: IEmailOptions) => {
  const link = document.createElement('a');

  link.href = getMailtoLink(options);
  link.target = '_blank';
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );
};
