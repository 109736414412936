import { useState, useEffect } from 'react';

import { ICarrotQuest } from '../interfaces/ICarrotQuest';
import { CarrotquestPlugin } from '../plugins/carrotquest';

const createDummyCarrotquest = (): any => ({
  auth: async () => Promise.resolve(),
  logout: async () => Promise.resolve(),
  identifyPlugin: async () => Promise.resolve(),
  open: async () => Promise.resolve(),
  close: async () => Promise.resolve(),
});

export default function useCarrotQuest() {
  const inIframe = window.self !== window.top;

  const [carrotQuest, setCarrotQuest] = useState<ICarrotQuest | null>(() =>
    inIframe ? createDummyCarrotquest() : CarrotquestPlugin,
  );

  useEffect(() => {
    if (inIframe) {
      setCarrotQuest(createDummyCarrotquest());
    } else {
      setCarrotQuest(CarrotquestPlugin);
    }
  }, [inIframe]);

  return carrotQuest;
}
