import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { IAchievementItem } from '@http/models/achievements';
import { v1 } from '@api/v1';
import { ELoadingStatus } from '@http/enums';

interface IAchievementListState {
  error?: string;
  loading: ELoadingStatus;
  items: IAchievementItem[];
  total: number;
  page: number;
}

const initialState: { [p: string]: IAchievementListState } = {};

export const loadData = createAsyncThunk<
  { userId: string; items: IAchievementItem[]; total: number },
  { userId: string; page?: number; take?: number },
  { rejectValue: string }
>('achievementList/loadData', async ({ userId, take = 25 }, { getState, rejectWithValue }) => {
  try {
    const state = getState() as RootState;
    const currentPage = state.achievementList[userId]?.page ?? 1;
    const response = await v1.user?.USER_ID.achievement.get(userId, { page: currentPage, take });
    if (response.errorCode) {
      return rejectWithValue(response.errorMsg!);
    }
    return {
      userId,
      items: response.items ?? [],
      total: response.total ?? 0,
    };
  } catch (error: any) {
    return rejectWithValue(error.message || 'Неизвестная ошибка');
  }
});

const slice = createSlice({
  name: 'achievementList',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<{ userId: string; error?: string }>) => {
      state[action.payload.userId] = state[action.payload.userId] ?? {};
      state[action.payload.userId].error = action.payload.error;
    },
    setLoading: (state, action: PayloadAction<{ userId: string; loading: ELoadingStatus }>) => {
      state[action.payload.userId] = state[action.payload.userId] ?? {};
      state[action.payload.userId].loading = action.payload.loading;
    },
    setItems: (state, action: PayloadAction<{ userId: string; items: IAchievementItem[] }>) => {
      state[action.payload.userId] = state[action.payload.userId] ?? {};
      state[action.payload.userId].items = action.payload.items;
    },
    setTotal: (state, action: PayloadAction<{ userId: string; total: number }>) => {
      state[action.payload.userId] = state[action.payload.userId] ?? {};
      state[action.payload.userId].total = action.payload.total;
    },
    setPage: (state, action: PayloadAction<{ userId: string; page: number }>) => {
      state[action.payload.userId] = state[action.payload.userId] ?? {};
      state[action.payload.userId].page = action.payload.page;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadData.pending, (state, action) => {
      const { userId } = action.meta.arg;
      state[userId] = state[userId] ?? { loading: ELoadingStatus.Succeeded, items: [], total: 0 };
      state[userId].loading = ELoadingStatus.Loading;
      state[userId].error = undefined;
    });
    builder.addCase(loadData.fulfilled, (state, action) => {
      const { userId, items, total } = action.payload;
      state[userId].items = items;
      state[userId].total = total;
      state[userId].loading = ELoadingStatus.Succeeded;
      state[userId].error = undefined;
    });
    builder.addCase(loadData.rejected, (state, action) => {
      const { userId } = action.meta.arg;
      state[userId].loading = ELoadingStatus.Failed;
      state[userId].error = action.payload as string;
    });
  },
});

const achievementList = {
  ...slice.actions,
  loadData,
  selectError: (userId: string) => (state: RootState) => state.achievementList[userId]?.error,
  selectLoading: (userId: string) => (state: RootState) => state.achievementList[userId]?.loading,
  selectItems: (userId: string) => (state: RootState) => state.achievementList[userId]?.items ?? [],
  selectTotal: (userId: string) => (state: RootState) => state.achievementList[userId]?.total ?? 0,
  selectPage: (userId: string) => (state: RootState) => state.achievementList[userId]?.page ?? 0,
};

export const achievementListReducer = slice.reducer;
export default achievementList;
