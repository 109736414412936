import { useContext } from 'react';
import { SchemeContext } from '@providers/SchemeProvider';

const useScheme = () => {
  const context = useContext(SchemeContext);

  if (!context) {
    throw new Error('useScheme must be used within a SchemeProvider');
  }

  const { scheme, isRoundFamily, title, vkId } = context;

  return {
    scheme,
    isRoundFamily,
    title,
    vkId,
  };
};

export default useScheme;
