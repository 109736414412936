import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { SkillTypeDetails } from '@http/api/v0_1/skill-types/skill-types';
import { IChallengeItem } from '@http/models/challenge-item';
import { v0_1 } from '@api/v0_1';
import { v1 } from '@api/v1';
import { ELoadingStatus } from '../../http/enums';

interface ISkillState {
  error?: string;
  loading?: ELoadingStatus;
  data?: SkillTypeDetails;
  challenge?: {
    error?: string;
    loading?: ELoadingStatus;
    items?: IChallengeItem[];
    total?: number;
  };
}

const initialState: { [p: string]: ISkillState } = {};

export const loadSkillData = createAsyncThunk(
  'skillDetails/loadSkillData',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await v0_1.skillTypes.get(id);
      if (response.errorCode) {
        if (response.errorCode === 403) {
          window.location.href = '/restricted';
          return rejectWithValue('Forbidden');
        }
        return rejectWithValue(response.errorMsg);
      }
      return { id, data: response };
    } catch (error) {
      return rejectWithValue('Ошибка загрузки данных');
    }
  },
);

export const loadChallengeData = createAsyncThunk(
  'skillDetails/loadChallengeData',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await v1.challenge.published.get({
        skills: { fetch: true, id: [Number.parseInt(id)] },
        rubrics: { fetch: true },
        attributes: { fetch: true },
      });
      if (response.errorCode) {
        return rejectWithValue(response.errorMsg);
      }
      return { id, items: response.items ?? [], total: response.total ?? 0 };
    } catch (error) {
      return rejectWithValue('Ошибка загрузки челленджей');
    }
  },
);

const slice = createSlice({
  name: 'skillDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Обработка загрузки данных навыка
      .addCase(loadSkillData.pending, (state, action) => {
        const id = action.meta.arg;
        state[id] = { ...state[id], loading: ELoadingStatus.Loading, error: undefined };
      })
      .addCase(loadSkillData.fulfilled, (state, action) => {
        const { id, data } = action.payload;
        state[id] = { ...state[id], loading: ELoadingStatus.Succeeded, data };
      })
      .addCase(loadSkillData.rejected, (state, action) => {
        const id = action.meta.arg;
        state[id] = {
          ...state[id],
          loading: ELoadingStatus.Failed,
          error: action.payload as string,
        };
      })
      // Обработка загрузки челленджей
      .addCase(loadChallengeData.pending, (state, action) => {
        const id = action.meta.arg;
        state[id] = {
          ...state[id],
          challenge: { ...state[id]?.challenge, loading: ELoadingStatus.Loading },
        };
      })
      .addCase(loadChallengeData.fulfilled, (state, action) => {
        const { id, items, total } = action.payload;
        state[id] = {
          ...state[id],
          challenge: { ...state[id]?.challenge, loading: ELoadingStatus.Succeeded, items, total },
        };
      })
      .addCase(loadChallengeData.rejected, (state, action) => {
        const id = action.meta.arg;
        state[id] = {
          ...state[id],
          challenge: {
            ...state[id]?.challenge,
            loading: ELoadingStatus.Failed,
            error: action.payload as string,
          },
        };
      });
  },
});

const skillDetails = {
  ...slice.actions,
  selectLoading: (id: string) => (state: RootState) => state.skillDetails[id]?.loading,
  selectError: (id: string) => (state: RootState) => state.skillDetails[id]?.error,
  selectData: (id: string) => (state: RootState) => state.skillDetails[id]?.data,
  loadData: loadSkillData,
  challengeList: {
    selectError: (id: string) => (state: RootState) => state.skillDetails[id]?.challenge?.error,
    selectLoading: (id: string) => (state: RootState) => state.skillDetails[id]?.challenge?.loading,
    selectItems: (id: string) => (state: RootState) =>
      state.skillDetails[id]?.challenge?.items ?? [],
    selectTotal: (id: string) => (state: RootState) =>
      state.skillDetails[id]?.challenge?.total ?? 0,
    loadData: loadChallengeData,
  },
};

export const skillDetailsReducer = slice.reducer;
export default skillDetails;
