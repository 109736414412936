import { NewsItem } from './../../http/api/v0_1/news/news';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { v0_1 } from '@api/v0_1';
import { HOTNEWS_KEY } from '../../constants/constants';
import { ELoadingStatus } from '../../http/enums';

function getSeenNewsId(userId: string) {
  const seenNews = localStorage.getItem(HOTNEWS_KEY);
  return !!seenNews && typeof seenNews !== 'number' ? JSON.parse(seenNews)[userId] : undefined;
}

function updateSeenNewsId(userId: string, newSeenId: number) {
  const seenNews = localStorage.getItem(HOTNEWS_KEY);
  let updatedSeenNews;
  if (seenNews) {
    updatedSeenNews = JSON.parse(seenNews);
    updatedSeenNews[userId] = newSeenId;
  } else {
    updatedSeenNews = { [userId]: newSeenId };
  }
  localStorage.setItem(HOTNEWS_KEY, JSON.stringify(updatedSeenNews));
}

interface IHotnewsState {
  loading: ELoadingStatus;
  error?: string;
  data?: NewsItem;
}

const initialState: IHotnewsState = {
  loading: ELoadingStatus.Idle,
};

export const loadData = createAsyncThunk<
  NewsItem | null,
  void,
  { state: RootState; rejectValue: string }
>('hotnews/loadData', async (_, { getState, rejectWithValue }) => {
  const userId = getState().identity.userId;
  try {
    const response = await v0_1.hotnews.get();
    const newsItems = response as NewsItem[];
    const newsItem = newsItems[0] ?? null;
    if (newsItem && newsItem.id && userId) {
      const seenNewsId = getSeenNewsId(userId);
      if (seenNewsId && seenNewsId === newsItem.id) {
        // Если новость уже просмотрена, возвращаем null для сброса состояния
        return null;
      } else {
        updateSeenNewsId(userId, newsItem.id);
        return newsItem;
      }
    }
    return rejectWithValue('Нет новостей или отсутствует userId');
  } catch (error: any) {
    return rejectWithValue(error.message || 'Ошибка загрузки новостей');
  }
});

const slice = createSlice({
  name: 'hotnews',
  initialState,
  reducers: {
    resetHotnews: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadData.pending, (state) => {
        state.loading = ELoadingStatus.Loading;
        state.error = undefined;
      })
      .addCase(loadData.fulfilled, (state, action) => {
        if (action.payload === null) {
          // Если новость уже просмотрена, сбрасываем состояние
          return initialState;
        } else {
          state.data = action.payload;
          state.loading = ELoadingStatus.Succeeded;
        }
      })
      .addCase(loadData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = ELoadingStatus.Failed;
      });
  },
});

const hotnews = {
  ...slice.actions,
  selectLoading: (state: RootState) => state.hotnews.loading,
  selectError: (state: RootState) => state.hotnews.error,
  selectData: (state: RootState) => state.hotnews.data,
  loadData,
};

export const hotnewsReducer = slice.reducer;
export default hotnews;
