import { union } from 'lodash';

import { IProjectItem } from '@http/models/project-item';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ELoadingStatus, EProjectSortingOrder } from '@http/enums';
import storage from '@storage/index';
import { IProjectListRequest } from '@http/models/project-list-request';
import { v2 } from '@api/v2';

export interface IProjectRequestProps {
  position?: number;
  take?: number;
  refresh?: boolean;
  direction?: 'next' | 'prev';
  sortingOrder?: EProjectSortingOrder;
  isUserSubscription?: boolean;
}

interface IFeedState {
  error?: string;
  hasMore: boolean;
  hasMoreUser: boolean;
  ids: number[];
  loading: ELoadingStatus;
  position: number;
  positionUserItems: number;
  refresh: boolean;
  scrollPosition: number;
  totalItemsCount: number;
  totalUserItemsCount: number;
  userIds: number[];
}

const initialState: IFeedState = {
  hasMore: true,
  hasMoreUser: true,
  ids: [],
  loading: ELoadingStatus.Idle,
  position: 0,
  positionUserItems: 0,
  refresh: true,
  scrollPosition: 0,
  totalItemsCount: 0,
  totalUserItemsCount: 0,
  userIds: [],
};

export const loadProjects = createAsyncThunk<
  { items: IProjectItem[]; total: number; isUserSubscription: boolean },
  IProjectRequestProps,
  { state: RootState }
>('feed/loadProjects', async (props, { dispatch, rejectWithValue }) => {
  const {
    position = 0,
    take = 10,
    sortingOrder,
    isUserSubscription = false,
    refresh = false,
    direction = 'next',
  } = props;

  let requestParams: IProjectListRequest = {
    position,
    take,
    medias: { fetch: true },
    direction,
    hideSecret: true,
  };

  if (isUserSubscription) {
    requestParams.author = { isFollowed: true };
  } else {
    requestParams = {
      ...requestParams,
      sortingOrder,
      refresh,
    };
  }

  try {
    const response = await v2.project.get(requestParams);

    if (response.errorCode) {
      return rejectWithValue(response.errorMsg);
    }

    const result = {
      items: response.items as IProjectItem[],
      total: response.total,
      isUserSubscription,
    };

    dispatch(storage.projects.addItems(result.items));

    return result;
  } catch (error) {
    return rejectWithValue('Произошла ошибка при загрузке проектов.');
  }
});

const slice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    setRefresh: (state, action: PayloadAction<boolean>) => {
      state.refresh = action.payload;
      if (action.payload) {
        state.ids = [];
        state.userIds = [];
        state.position = 0;
        state.positionUserItems = 0;
        state.hasMore = true;
        state.hasMoreUser = true;
      }
    },
    setScrollPosition: (state, action: PayloadAction<number>) => {
      state.scrollPosition = action.payload;
    },
    setPositionUserItems: (state, action: PayloadAction<number>) => {
      state.positionUserItems = action.payload;
    },
    deleteId: (state, action: PayloadAction<number>) => {
      state.ids = state.ids.filter((id) => id !== action.payload);
    },
    deleteUserId: (state, action: PayloadAction<number>) => {
      state.userIds = state.userIds.filter((id) => id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadProjects.pending, (state) => {
        state.loading = ELoadingStatus.Loading;
        state.error = undefined;
      })
      .addCase(loadProjects.fulfilled, (state, action) => {
        const { isUserSubscription, items, total } = action.payload;
        const take = action.meta.arg.take || 10;
        const hasMore = items.length > 0;

        state.loading = ELoadingStatus.Succeeded;
        state.refresh = false;

        // Определяем, какие поля обновлять в зависимости от типа ленты
        const setHasMore = isUserSubscription ? 'hasMoreUser' : 'hasMore';
        const setIds = isUserSubscription ? 'userIds' : 'ids';
        const setTotalCount = isUserSubscription ? 'totalUserItemsCount' : 'totalItemsCount';
        const setPosition = isUserSubscription ? 'positionUserItems' : 'position';

        // Обновляем флаг hasMore
        state[setHasMore] = hasMore;

        if (hasMore) {
          const projectIds = items.map((item) => item.id);
          state[setIds] = union(state[setIds], projectIds);
          state[setTotalCount] = total;
          state[setPosition] = state[setPosition] + take;
        }
      })
      .addCase(loadProjects.rejected, (state, action) => {
        state.loading = ELoadingStatus.Failed;
        state.error = action.payload as string;
        state.hasMore = false;
        state.hasMoreUser = false;
      });
  },
});

//todo возможно не все методы отсюда используются (удалить неиспользуемые)
const feed = {
  ...slice.actions,
  selectRefresh: (state: RootState) => state.feed.refresh,
  selectLoading: (state: RootState) => state.feed.loading,
  selectScrollPosition: (state: RootState) => state.feed.scrollPosition,
  selectPosition: (state: RootState) => state.feed.position,
  selectPositionUserItems: (state: RootState) => state.feed.positionUserItems,
  selectError: (state: RootState) => state.feed.error,
  selectItems: (state: RootState) => state.feed.ids.map((id) => state.projects[id]).filter(Boolean),
  selectUserItems: (state: RootState) =>
    state.feed.userIds.map((id) => state.projects[id]).filter(Boolean),
  selectTotalItemsCount: (state: RootState) => state.feed.totalItemsCount,
  selectTotalUserItemsCount: (state: RootState) => state.feed.totalUserItemsCount,
  selectHasMore: (state: RootState) => state.feed.hasMore,
  selectHasMoreUser: (state: RootState) => state.feed.hasMoreUser,
};

export const feedReducer = slice.reducer;
export default feed;
