import { useMemo } from 'react';

import { ERequiredPupilFields, ERequiredStudentFields, ERole } from '@http/enums';

interface IUseRequiredFieldsProps {
  role?: ERole;
  requiredStudentFields?: string[];
  requiredPupilFields?: string[];
}

const requiredFieldsMappingsByRole: Partial<
  Record<ERole, Record<string, ERequiredStudentFields | ERequiredPupilFields>>
> = {
  [ERole.Student]: {
    firstName: ERequiredStudentFields.FirstName,
    lastName: ERequiredStudentFields.LastName,
    fatherName: ERequiredStudentFields.MiddleName,
    birthday: ERequiredStudentFields.Birthdate,
    gender: ERequiredStudentFields.Gender,
    educationalInstitution: ERequiredStudentFields.EducationalInstitution,
    speciality: ERequiredStudentFields.Speciality,
    groupNumber: ERequiredStudentFields.GroupNumber,
  },
  [ERole.Pupil]: {
    firstName: ERequiredPupilFields.FirstName,
    lastName: ERequiredPupilFields.LastName,
    fatherName: ERequiredPupilFields.MiddleName,
    birthday: ERequiredPupilFields.Birthdate,
    gender: ERequiredPupilFields.Gender,
    countryName: ERequiredPupilFields.EducationalInstitutionSchool,
    regionName: ERequiredPupilFields.EducationalInstitutionSchool,
    localityName: ERequiredPupilFields.EducationalInstitutionSchool,
    schoolName: ERequiredPupilFields.EducationalInstitutionSchool,
    classParallel: ERequiredPupilFields.Class,
  },
};

export default function useRequiredFields(props: IUseRequiredFieldsProps) {
  const { role, requiredStudentFields, requiredPupilFields } = props;

  const requiredFields = useMemo(() => {
    if (!role) {
      return {};
    }

    const requiredFieldsByRole: Partial<Record<ERole, string[] | undefined>> = {
      [ERole.Student]: requiredStudentFields,
      [ERole.Pupil]: requiredPupilFields,
    };

    const fields = requiredFieldsByRole?.[role];
    const fieldMapping = requiredFieldsMappingsByRole[role];

    if (!fields || !fieldMapping) return {};

    return getRequiredFields(fields, fieldMapping);
  }, [props.role, requiredStudentFields, requiredPupilFields]);

  return requiredFields;
}

function getRequiredFields(requiredFields: string[], fieldMapping: Record<string, string>) {
  const fields: Record<string, boolean> = {};
  for (const [fieldName, requiredFieldConstant] of Object.entries(fieldMapping)) {
    fields[fieldName] = requiredFields.includes(requiredFieldConstant);
  }
  return fields;
}
