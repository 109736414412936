// Отслеживает предыдущий маршрут и сохраняет его в localStorage, исключая определенные пути.
// hooks/useTrackRouteChange.js
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

export default function useTrackRouteChange() {
  const location = useLocation();
  const previousPathRef = useRef(location.pathname);
  const excludedPaths = ['authorization', 'create-project'];

  const isExcluded = (path: string) =>
    excludedPaths.some((excludedPath) => path.includes(excludedPath));

  useEffect(() => {
    const currentPath = location.pathname;

    if (!isExcluded(previousPathRef.current)) {
      localStorage.setItem('previous_location', previousPathRef.current);
    }

    previousPathRef.current = currentPath;
  }, [location.pathname]);
}
