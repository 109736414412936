import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { EBannerType, ELoadingStatus } from '@http/enums';
import { IEventItem } from '@http/models/event-item';
import { v1 } from '@api/v1';

interface IEventState {
  loading: ELoadingStatus;
  error?: string;
  data: IEventItem;
}

const initialState: { [p: string]: IEventState } = {};

const createState = (): IEventState => ({
  loading: ELoadingStatus.Idle,
  data: {},
});

export const loadData = createAsyncThunk<
  { id: number; data: IEventItem },
  number,
  { rejectValue: string }
>('eventDetails/loadData', async (id, { rejectWithValue }) => {
  try {
    const response = await v1.event.id.get(id);

    if (response.errorCode) {
      return rejectWithValue(response.errorMsg!);
    }

    return { id, data: response };
  } catch (error: any) {
    return rejectWithValue(error.message || 'Ошибка загрузки события');
  }
});

const slice = createSlice({
  name: 'eventDetails',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<{ id: number; loading: ELoadingStatus }>) => {
      state[action.payload.id] = state[action.payload.id] ?? createState();
      state[action.payload.id].loading = action.payload.loading;
    },
    setError: (state, action: PayloadAction<{ id: number; error?: string }>) => {
      state[action.payload.id] = state[action.payload.id] ?? createState();
      state[action.payload.id].error = action.payload.error;
    },
    setData: (state, action: PayloadAction<{ id: number; data?: IEventItem }>) => {
      state[action.payload.id] = state[action.payload.id] ?? createState();
      state[action.payload.id].data = action.payload.data ?? {};
    },
    setUserSubscribed: (
      state,
      action: PayloadAction<{ id: number; isUserSubscribed: boolean }>,
    ) => {
      state[action.payload.id] = state[action.payload.id] ?? createState();
      state[action.payload.id].data = state[action.payload.id].data ?? {};
      state[action.payload.id].data.isUserSubscribed = action.payload.isUserSubscribed;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadData.pending, (state, action) => {
        const id = action.meta.arg;
        state[id] = state[id] ?? createState();
        state[id].loading = ELoadingStatus.Loading;
        state[id].error = undefined;
      })
      .addCase(loadData.fulfilled, (state, action) => {
        const { id, data } = action.payload;
        state[id].data = data;
        state[id].loading = ELoadingStatus.Succeeded;
      })
      .addCase(loadData.rejected, (state, action) => {
        const id = action.meta.arg;
        state[id] = state[id] ?? createState();
        state[id].error = action.payload as string;
        state[id].loading = ELoadingStatus.Failed;
      });
  },
});

const { setLoading, setError, setData, setUserSubscribed } = slice.actions;

const eventDetails = {
  setLoading: (id: number, loading: ELoadingStatus) => setLoading({ id, loading }),
  setError: (id: number, error?: string) => setError({ id, error }),
  setData: (id: number, data?: IEventItem) => setData({ id, data }),
  selectError: (id: number) => (state: RootState) => state.eventDetails[id]?.error,
  selectLoading: (id: number) => (state: RootState) => state.eventDetails[id]?.loading,
  selectData: (id: number) => (state: RootState) => state.eventDetails[id]?.data ?? {},
  selectType: (id: number) => (state: RootState) => state.eventDetails[id]?.data?.type,
  selectEvents: (id: number) => (state: RootState) => state.eventDetails[id]?.data?.events ?? [],
  selectPrizes: (id: number) => (state: RootState) => state.eventDetails[id]?.data?.prizes ?? [],
  selectMainBanner: (id: number) => (state: RootState) =>
    state.eventDetails[id]?.data?.banners?.find((x) => x.type === EBannerType.Main) ?? {},
  selectPrizesBanner: (id: number) => (state: RootState) =>
    state.eventDetails[id]?.data?.banners?.find((x) => x.type === EBannerType.Prizes) ?? {},
  selectChallenges: (id: number) => (state: RootState) =>
    state.eventDetails[id]?.data?.challenges ?? [],
  setUserSubscribed: (id: number, isUserSubscribed: boolean) =>
    setUserSubscribed({ id, isUserSubscribed }),
  selectIsSubscribedFestival: (id: number) => (state: RootState) =>
    state.eventDetails[id]?.data?.isUserSubscribed ?? false,
  loadData,
};

export const eventDetailsReducer = slice.reducer;
export default eventDetails;
