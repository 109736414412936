import { batch } from 'react-redux';
import i18next from 'i18next';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { IProjectItem } from '@http/models/project-item';
import { MediaItemDto } from '@api/v0_1/challenges/challenges-instruction';
import { IChallengeItem } from '@http/models/challenge-item';
import { v1 } from '@api/v1';
import { v0_1 } from '@api/v0_1';
import { ELoadingStatus } from '@http/enums';
import storage from '..';
import { IProjectList } from '../../http/models/project-list';

interface IChallengeState {
  error?: string;
  loading?: ELoadingStatus;
  data?: IChallengeItem;
  instructions: {
    error?: string;
    loading?: ELoadingStatus;
    items: MediaItemDto[];
    total?: number;
  };
  allProjects: {
    error?: string;
    loading?: ELoadingStatus;
    ids?: number[];
    total?: number;
  };
  myProjects: {
    error?: string;
    loading?: ELoadingStatus;
    ids?: number[];
    total?: number;
  };
}

const initialChallengeState: IChallengeState = {
  error: undefined,
  loading: ELoadingStatus.Idle,
  data: undefined,
  instructions: {
    error: undefined,
    loading: ELoadingStatus.Idle,
    items: [],
    total: 0,
  },
  allProjects: {
    error: undefined,
    loading: ELoadingStatus.Idle,
    ids: [],
    total: 0,
  },
  myProjects: {
    error: undefined,
    loading: ELoadingStatus.Idle,
    ids: [],
    total: 0,
  },
};

const initialState: { [p: string]: IChallengeState } = {};

export const toggleFavorite = createAsyncThunk<
  { id: number; liked: boolean },
  number,
  { state: RootState }
>('challengeDetails/toggleFavorite', async (id, { getState }) => {
  const state = getState();
  await v0_1.challenges.CHALLENGE_ID.likes.post(id);

  return { id, liked: !state.challengeDetails[id]?.data?.liked };
});

export const loadData = createAsyncThunk<{ id: number; data: IChallengeItem }, number>(
  'challengeDetails/loadData',
  async (id, { rejectWithValue }) => {
    const response = await v1.challenge.CHALLENGE_ID.get(id);
    if (response.errorCode) {
      if (response.errorCode === 403) {
        window.location.href = '/restricted';
        return rejectWithValue('403');
      }
      if (response.errorMsg?.startsWith('<!DOCTYPE html>')) {
        return rejectWithValue(response.errorCode + ' ' + i18next.t('common__msg_error_server'));
      }
      return rejectWithValue(response.errorMsg);
    }

    return { id, data: response };
  },
);

export const loadInstructions = createAsyncThunk<
  { id: number; items: MediaItemDto[]; total: number },
  number
>('challengeDetails/instructions/loadData', async (id, { rejectWithValue }) => {
  const response = await v0_1.challenges.CHALLENGE_ID.instruction.get(id);
  if (response.errorCode) {
    return rejectWithValue(response.errorMsg);
  }
  return { id, items: response.length ? response : [], total: response.length };
});

export const loadAllProjects = createAsyncThunk<
  { id: number; items: IProjectItem[]; total: number },
  { id: number; page: number }
>('challengeDetails/allProjects/loadData', async ({ id, page }, { dispatch, rejectWithValue }) => {
  const response: IProjectList = await v1.project.get({
    challengeId: [id],
    skills: { fetch: true },
    medias: { fetch: true },
    take: 25,
    page,
  });

  if (response.errorCode) {
    return rejectWithValue(response.errorMsg);
  }

  dispatch(storage.projects.addItems(response.items));

  return { id, items: response.items, total: response.total };
});

export const loadMyProjects = createAsyncThunk<
  { id: number; items: IProjectItem[]; total: number },
  { challengeId: number; userId?: string }
>(
  'challengeDetails/myProjects/loadData',
  async ({ challengeId, userId }, { dispatch, rejectWithValue }) => {
    if (!userId) {
      return rejectWithValue('User ID is required');
    }

    const response: IProjectList = await v1.project.get({
      challengeId: [challengeId],
      skills: { fetch: true },
      medias: { fetch: true },
      author: { id: [userId] },
    });

    if (response.errorCode) {
      return rejectWithValue(response.errorMsg);
    }

    dispatch(storage.projects.addItems(response.items));

    return { id: challengeId, items: response.items, total: response.total };
  },
);

const slice = createSlice({
  name: 'challengeDetails',
  initialState,
  reducers: {
    setLiked: (state, action: PayloadAction<{ id: number; liked?: boolean }>) => {
      state[action.payload.id] = state[action.payload.id] ?? {};
      state[action.payload.id].data = state[action.payload.id].data ?? {};
      state[action.payload.id].data!.liked = action.payload.liked;
      // state[action.payload.id].data!.userLiked = action.payload.liked; // todo
      state[action.payload.id].data!.likesCount = action.payload.liked
        ? (state[action.payload.id].data!.likesCount || 0) + 1
        : (state[action.payload.id].data!.likesCount || 0) - 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(toggleFavorite.pending, (state, action) => {
        state[action.meta.arg] = state[action.meta.arg] ?? {};
        state[action.meta.arg].loading = ELoadingStatus.Loading;
      })
      .addCase(toggleFavorite.fulfilled, (state, action) => {
        const { id, liked } = action.payload;
        if (state[id]) {
          state[id].loading = ELoadingStatus.Succeeded;
          state[id].data = state[id].data ?? {};
          state[id].data!.liked = liked;
          state[id].data!.likesCount = liked
            ? (state[id].data!.likesCount || 0) + 1
            : (state[id].data!.likesCount || 0) - 1;
        }
      })
      .addCase(toggleFavorite.rejected, (state, action) => {
        state[action.meta.arg].loading = ELoadingStatus.Failed;
      })
      .addCase(loadData.pending, (state, action) => {
        state[action.meta.arg] = state[action.meta.arg] ?? {};

        if (!state[action.meta.arg]) {
          state[action.meta.arg] = {
            ...initialChallengeState,
          };
        }

        state[action.meta.arg].loading = ELoadingStatus.Loading;
        state[action.meta.arg].error = undefined;
      })
      .addCase(loadData.fulfilled, (state, action) => {
        const { id, data } = action.payload;

        state[id].data = data;
        state[id].loading = ELoadingStatus.Succeeded;
      })
      .addCase(loadData.rejected, (state, action) => {
        state[action.meta.arg].error = action.payload as string;
        state[action.meta.arg].loading = ELoadingStatus.Failed;
      })
      .addCase(loadInstructions.pending, (state, action) => {
        if (!state[action.meta.arg]) {
          state[action.meta.arg] = {
            ...initialChallengeState,
          };
        }

        state[action.meta.arg].instructions.loading = ELoadingStatus.Loading;
      })
      .addCase(loadInstructions.fulfilled, (state, action) => {
        const { id, items, total } = action.payload;

        if (!state[id]) {
          state[id] = {
            ...initialChallengeState,
          };
        }

        state[id].instructions.items = items;
        state[id].instructions.total = total;
        state[id].instructions.loading = ELoadingStatus.Succeeded;
      })
      .addCase(loadInstructions.rejected, (state, action) => {
        state[action.meta.arg].instructions.error = action.payload as string;
        state[action.meta.arg].instructions.loading = ELoadingStatus.Failed;
      })
      .addCase(loadAllProjects.pending, (state, action) => {
        const { id } = action.meta.arg;

        if (!state[id]) {
          state[id] = {
            ...initialChallengeState,
          };
        }

        state[id].allProjects.loading = ELoadingStatus.Loading;
      })
      .addCase(loadAllProjects.fulfilled, (state, action) => {
        const { id, items, total } = action.payload;

        state[id].allProjects.ids = items.map((item) => item.id);
        state[id].allProjects.total = total;
        state[id].allProjects.loading = ELoadingStatus.Succeeded;
      })
      .addCase(loadAllProjects.rejected, (state, action) => {
        const { id } = action.meta.arg;

        state[id].allProjects.error = action.payload as string;
        state[id].allProjects.loading = ELoadingStatus.Failed;
      })
      .addCase(loadMyProjects.pending, (state, action) => {
        const { challengeId } = action.meta.arg;
        state[challengeId] = state[challengeId] ?? { myProjects: {} };
        state[challengeId].myProjects.loading = ELoadingStatus.Loading;
      })
      .addCase(loadMyProjects.fulfilled, (state, action) => {
        const { id, items, total } = action.payload;

        state[id].myProjects.ids = items.map((item) => item.id);
        state[id].myProjects.total = total;
        state[id].myProjects.loading = ELoadingStatus.Succeeded;
      })
      .addCase(loadMyProjects.rejected, (state, action) => {
        const { challengeId } = action.meta.arg;
        state[challengeId] = state[challengeId] ?? { myProjects: {} };
        state[challengeId].myProjects.error = action.payload as string;
        state[challengeId].myProjects.loading = ELoadingStatus.Failed;
      });
  },
});

const challengeDetails = {
  ...slice.actions,
  selectLoading: (id: number) => (state: RootState) =>
    state.challengeDetails[id]?.loading ?? ELoadingStatus.Idle,
  selectError: (id: number) => (state: RootState) => state.challengeDetails[id]?.error,
  selectData: (id: number) => (state: RootState) => state.challengeDetails[id]?.data,
  selectLiked: (id: number) => (state: RootState) =>
    state.challengeDetails[id]?.data?.liked ?? false,
  selectActionButton: (id: number) => (state: RootState) =>
    state.challengeDetails[id]?.data?.actionButton ?? {},
  toggleFavorite,
  loadData,
  instructions: {
    selectError: (id: number) => (state: RootState) =>
      state.challengeDetails[id]?.instructions?.error,
    selectLoading: (id: number) => (state: RootState) =>
      state.challengeDetails[id]?.instructions?.loading ?? ELoadingStatus.Idle,
    selectItems: (id: number) => (state: RootState) =>
      state.challengeDetails[id]?.instructions?.items ?? [],
    selectTotal: (id: number) => (state: RootState) =>
      state.challengeDetails[id]?.instructions?.total ?? 0,
    loadData: loadInstructions,
  },
  allProjects: {
    selectError: (id: number) => (state: RootState) =>
      state.challengeDetails[id]?.allProjects?.error,
    selectLoading: (id: number) => (state: RootState) =>
      state.challengeDetails[id]?.allProjects?.loading ?? ELoadingStatus.Idle,
    selectItems: (id: number) => (state: RootState) =>
      state.challengeDetails[id]?.allProjects?.ids
        ?.map((id: number) => state.projects[id])
        .filter(Boolean) || [],
    selectTotal: (id: number) => (state: RootState) =>
      state.challengeDetails[id]?.allProjects?.total ?? 0,
    loadData: loadAllProjects,
  },
  myProjects: {
    selectError: (id: number) => (state: RootState) =>
      state.challengeDetails[id]?.myProjects?.error,
    selectLoading: (id: number) => (state: RootState) =>
      state.challengeDetails[id]?.myProjects?.loading ?? ELoadingStatus.Idle,
    selectItems: (id: number) => (state: RootState) =>
      state.challengeDetails[id]?.myProjects?.ids
        ?.map((id: number) => state.projects[id])
        .filter(Boolean) || [],
    selectTotal: (id: number) => (state: RootState) =>
      state.challengeDetails[id]?.myProjects?.total ?? 0,
    loadData: loadMyProjects,
  },
};

export const challengeDetailsReducer = slice.reducer;
export default challengeDetails;
