import { useEffect } from 'react';

import { useScheme } from '@hooks/index';

export default function useDynamicIcons() {
  const { scheme } = useScheme();

  const TOUCH_ICON_SIZES = ['180', '192', '512'];

  useEffect(() => {
    function createAndSetIcon(scheme: string, size: string | null, isFavicon = false) {
      let href;

      if (isFavicon) {
        href = `${process.env.PUBLIC_URL}/img/touch-icons/${scheme}/favicon.svg`;
      } else {
        href = `${process.env.PUBLIC_URL}/img/touch-icons/${scheme}/${size}.png`;
      }

      const linkElement = document.createElement('link');
      linkElement.rel = isFavicon ? 'icon' : 'apple-touch-icon';
      linkElement.href = href;

      if (!isFavicon) {
        linkElement.setAttribute('type', 'image/png');
      }

      if (size) {
        linkElement.setAttribute('sizes', `${size}x${size}`);
      }

      document.head.appendChild(linkElement);
    }

    // Удаление существующих иконок и favicon
    const existingLinkElements = document.querySelectorAll("link[rel*='icon']");
    existingLinkElements.forEach((linkElement) => {
      document.head.removeChild(linkElement);
    });

    TOUCH_ICON_SIZES.forEach((sizes) => {
      scheme && createAndSetIcon(scheme, sizes);
    });

    // Добавление favicon
    scheme && createAndSetIcon(scheme, null, true);
  }, [scheme]);

  return null;
}
