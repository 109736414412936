import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import {
  SkillAchievementItem,
  UserAchievementListRequest,
} from '@http/api/v1/user/user-achievement';
import { IAchievementItem } from '@http/models/achievements';
import { v1 } from '@api/v1';
import { ELoadingStatus } from '../../http/enums';

interface UserAchievementState {
  error?: string;
  loading: ELoadingStatus;
  itemsSkill?: SkillAchievementItem[];
  items?: IAchievementItem[];
  total: number;
}

const initialState: { [p: string]: UserAchievementState } = {};

export const loadSkill = createAsyncThunk(
  'userAchievement/loadSkill',
  async (userId: string, { rejectWithValue }) => {
    if (!userId) return rejectWithValue('No user ID');

    try {
      const response = await v1.user.USER_ID.achievement.skill.get(userId);
      if (response.errorCode) {
        return rejectWithValue(response.errorMsg);
      }
      return { userId, items: response.items ?? [], total: response.total ?? 0 };
    } catch (error) {
      return rejectWithValue('Ошибка загрузки списка скиллов');
    }
  },
);

export const loadData = createAsyncThunk(
  'userAchievement/loadData',
  async (
    {
      userId,
      request,
    }: {
      userId: string;
      request: UserAchievementListRequest;
    },
    { rejectWithValue },
  ) => {
    if (!userId) return rejectWithValue('No user ID');

    try {
      const response = await v1.user.USER_ID.achievement.get(userId, request);
      if (response.errorCode) {
        return rejectWithValue(response.errorMsg);
      }
      return { userId, items: response.items ?? [] };
    } catch (error) {
      return rejectWithValue('Ошибка загрузки списка ачивок пользователя');
    }
  },
);

const slice = createSlice({
  name: 'UserAchievement',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<{ userId: string; error?: string }>) => {
      state[action.payload.userId] = state[action.payload.userId] ?? {};
      state[action.payload.userId].error = action.payload.error;
    },
    setLoading: (state, action: PayloadAction<{ userId: string; loading: ELoadingStatus }>) => {
      state[action.payload.userId] = state[action.payload.userId] ?? {};
      state[action.payload.userId].loading = action.payload.loading;
    },
    setItemsSkill: (
      state,
      action: PayloadAction<{ userId: string; items: SkillAchievementItem[] }>,
    ) => {
      state[action.payload.userId] = state[action.payload.userId] ?? {};
      state[action.payload.userId].itemsSkill = action.payload.items;
    },
    setItems: (state, action: PayloadAction<{ userId: string; items: IAchievementItem[] }>) => {
      state[action.payload.userId] = state[action.payload.userId] ?? {};
      state[action.payload.userId].items = action.payload.items;
    },
    setTotal: (state, action: PayloadAction<{ userId: string; total: number }>) => {
      state[action.payload.userId] = state[action.payload.userId] ?? {};
      state[action.payload.userId].total = action.payload.total;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadSkill.pending, (state, action) => {
        const userId = (action.meta.arg as any)?.userId;
        if (userId) state[userId] = { ...state[userId], loading: ELoadingStatus.Loading };
      })
      .addCase(loadSkill.fulfilled, (state, action) => {
        const { userId, items, total } = action.payload;
        state[userId] = {
          ...state[userId],
          itemsSkill: items,
          total,
          loading: ELoadingStatus.Succeeded,
        };
      })
      .addCase(loadSkill.rejected, (state, action) => {
        const userId = (action.meta.arg as any)?.userId;
        if (userId)
          state[userId] = {
            ...state[userId],
            error: action.payload as string,
            loading: ELoadingStatus.Failed,
          };
      })
      .addCase(loadData.pending, (state, action) => {
        const userId = (action.meta.arg as any)?.userId;
        if (userId) state[userId] = { ...state[userId], loading: ELoadingStatus.Loading };
      })
      .addCase(loadData.fulfilled, (state, action) => {
        const { userId, items } = action.payload;
        state[userId] = { ...state[userId], items, loading: ELoadingStatus.Succeeded };
      })
      .addCase(loadData.rejected, (state, action) => {
        const userId = (action.meta.arg as any)?.userId;
        if (userId)
          state[userId] = {
            ...state[userId],
            error: action.payload as string,
            loading: ELoadingStatus.Failed,
          };
      });
  },
});

const userAchievement = {
  ...slice.actions,
  selectError: (userId: string) => (state: RootState) => state.userAchievement[userId]?.error,
  selectLoading: (userId: string) => (state: RootState) => state.userAchievement[userId]?.loading,
  selectItemsSkill: (userId: string) => (state: RootState) =>
    state.userAchievement[userId]?.itemsSkill ?? [],
  selectItems: (userId: string) => (state: RootState) => state.userAchievement[userId]?.items ?? [],
  selectTotal: (userId: string) => (state: RootState) => state.userAchievement[userId]?.total ?? 0,
  loadSkill,
  loadData,
};

export const userAchievementReducer = slice.reducer;
export default userAchievement;
