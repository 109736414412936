interface IGetMailtoParams {
  email: string;
  body?: string;
  subject?: string;
}

export const getMailtoLink = (params: IGetMailtoParams) => {
  const { email, body = '', subject = '' } = params;
  const formattedBody = encodeURIComponent(body);

  return `mailto:${email}?subject=${subject}&body=${formattedBody}`;
};
