import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateRange } from 'react-day-picker';
import { uniqBy } from 'lodash';

import { RootState } from '../store';
import { IEventItem } from '@http/models/event-item';
import { getISODate } from '@utils/get-iso-date';
import { ICalendarRequest } from '@http/models/calendar-request';
import { ELoadingStatus } from '@http/enums';
import { TLoadingStatus } from '../../types/loading-status';
import { v1 } from '@api/v1';

interface ICalendarItem {
  dateString: string;
  date: Date;
  events: IEventItem[];
}

interface ICalendarState {
  beginDate?: Date;
  endDate?: Date;
  currentLocation?: number | undefined;
  error?: string;
  items?: ICalendarItem[];
  status?: ELoadingStatus;
  page: number;
  dateRange?: DateRange;
  selectedShortRange: number;
  take: number;
  total: number;
}

const initialState: ICalendarState = {
  items: [],
  currentLocation: 0,
  status: ELoadingStatus.Idle,
  page: 1,
  take: 10,
  total: 0,
  selectedShortRange: 1,
};

export const loadData = createAsyncThunk<
  { items: ICalendarItem[]; total: number },
  boolean | undefined,
  { rejectValue: string }
>('calendar/loadData', async (loadMore = false, { getState, rejectWithValue }) => {
  const state = getState() as RootState;
  const currentPage = loadMore ? state.calendar.page + 1 : 1;

  const requestParams: ICalendarRequest = {
    banners: { fetch: true },
    page: currentPage,
    take: state.calendar.take,
  };

  if (state.calendar.currentLocation) {
    requestParams.locationIds = [state.calendar.currentLocation];
  }
  if (state.calendar.beginDate) {
    requestParams.beginDate = getISODate(state.calendar.beginDate);
  }
  if (state.calendar.endDate) {
    requestParams.endDate = getISODate(state.calendar.endDate);
  }

  try {
    const response = await v1.calendar.get(requestParams);

    if (response.errorCode) {
      return rejectWithValue(response.errorMsg!);
    }

    return {
      items: response.items ?? [],
      total: response.total ?? 0,
    };
  } catch (error: any) {
    return rejectWithValue(error.message || 'Ошибка загрузки календаря');
  }
});

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    reset: () => initialState,
    setStatus: (state, action: PayloadAction<TLoadingStatus>) => {
      state.status = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setDateRange: (state, action: PayloadAction<DateRange | undefined>) => {
      state.dateRange = action.payload;
    },
    setTake: (state, action: PayloadAction<number>) => {
      state.take = action.payload;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setCurrentLocation: (state, action: PayloadAction<number>) => {
      state.currentLocation = action.payload;
    },
    setSelectedShortRange: (state, action: PayloadAction<number>) => {
      state.selectedShortRange = action.payload;
    },
    setItems: (state, action: PayloadAction<ICalendarItem[]>) => {
      state.items = action.payload;
    },
    addItems: (state, action: PayloadAction<ICalendarItem[]>) => {
      state.items = uniqBy([...(state.items || []), ...action.payload], 'dateString');
    },
    setDate: (state, action: PayloadAction<DateRange | undefined>) => {
      state.beginDate = action.payload?.from;
      state.endDate = action.payload?.to;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadData.pending, (state) => {
        state.status = ELoadingStatus.Loading;
        state.error = undefined;
      })
      .addCase(loadData.fulfilled, (state, action) => {
        const { items, total } = action.payload;
        const isLoadMore = action.meta.arg;

        state.items = isLoadMore ? uniqBy([...(state.items ?? []), ...items], 'dateString') : items;
        state.total = total;
        state.page = isLoadMore ? state.page + 1 : 1;
        state.status = ELoadingStatus.Succeeded;
      })
      .addCase(loadData.rejected, (state, action) => {
        state.status = ELoadingStatus.Failed;
        state.error = action.payload as string;
      });
  },
});

const calendar = {
  ...slice.actions,
  selectTotal: (state: RootState) => state.calendar.total,
  selectShortRange: (state: RootState) => state.calendar.selectedShortRange,
  selectBeginDate: (state: RootState) => state.calendar.beginDate,
  selectEndDate: (state: RootState) => state.calendar.endDate,
  selectStatus: (state: RootState) => state.calendar.status,
  selectCurrentLocation: (state: RootState) => state.calendar.currentLocation,
  selectDateRange: (state: RootState) => state.calendar.dateRange,
  selectError: (state: RootState) => state.calendar.error,
  selectEvents: (state: RootState) => state.calendar.items,
  loadData,
};

export const calendarReducer = slice.reducer;
export default calendar;
