import React, { useEffect, useMemo } from 'react';

interface UseStickyTopConfig {
  className?: string;
  top?: number;
  subs?: number;
}

export default function useStickyTop<T>(
  ref: React.MutableRefObject<T> | null,
  config?: UseStickyTopConfig,
) {
  const classNames = useMemo(() => {
    const items = ['sticky'];
    if (config?.className) {
      config?.className.split(' ').map((item) => items.push(item));
    }
    return items;
  }, [config?.className]);

  useEffect(() => {
    if (!ref?.current) {
      return;
    }

    const current = ref.current as any;
    const offsetTop =
      (current.offsetTop as number) + ((config?.top ? (config?.top - 1) * -1 : undefined) ?? 1);

    const listener = () => {
      if (current.classList) {
        if (window.scrollY >= offsetTop + (config?.subs ? config?.subs : 0)) {
          classNames.forEach((className) => {
            if (!current.classList.contains(className)) {
              const currentHeight = current.clientHeight;
              const newElement = document.createElement('div');
              newElement.classList.add('sticky-after');
              newElement.style.height = `${currentHeight}px`;
              current.before(newElement);
              current.style.top = `${config?.top ?? 0}px`;
              current.classList.add(className);
            }
          });
        } else {
          classNames.forEach((className) => {
            if (current.classList.contains(className)) {
              const addedElement = document.querySelector('.sticky-after');
              addedElement?.remove();
              current.classList?.remove(className);
              current.style.top = 'auto';
            }
          });
        }
      }
    };

    window.addEventListener('scroll', listener);

    return () => {
      window.removeEventListener('scroll', listener);
      const addedElement = document.querySelector('.sticky-after');
      addedElement?.remove();
    };
  }, [classNames, config?.top, ref, config?.subs]);
}
