import { useContext } from 'react';
import { DialogContext } from '@providers/DialogProvider';

export default function useDialog() {
  const context = useContext(DialogContext);

  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider');
  }

  const { showDialog, hideDialog, updateDialog } = context;

  return {
    showDialog,
    hideDialog,
    updateDialog,
  };
}
