import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { SkillTypeListItem } from '@api/v1/skill/skill';
import { isEmpty, uniqBy } from 'lodash';
import { IRubricItem } from '@models/rubric-item';
import { v1 } from '@api/v1';
import { ELoadingStatus } from '../../http/enums';

interface ISkillListState {
  loading: ELoadingStatus;
  items: SkillTypeListItem[];
  scrollPosition: number;
  error?: string;
  total: number;
  page: number;
  take: number;
}

const initialState: ISkillListState = {
  loading: ELoadingStatus.Idle,
  items: [],
  total: 0,
  scrollPosition: 0,
  page: 1,
  take: 10,
};

export const loadSkillList = createAsyncThunk(
  'skillList/loadSkillList',
  async (
    { rubricList, loadMore }: { rubricList?: IRubricItem[]; loadMore?: boolean },
    { getState, rejectWithValue },
  ) => {
    try {
      const state = getState() as RootState;
      const rubricId = isEmpty(rubricList) ? [] : rubricList?.map((i) => i.id);
      const response = await v1.skill.get({
        rubricId,
        page: loadMore ? state.skillList.page + 1 : 1,
        take: state.skillList.take,
      });

      if (response.errorCode) {
        return rejectWithValue(response.errorMsg);
      }

      return { items: response.items ?? [], total: response.total ?? 0, loadMore };
    } catch (error) {
      return rejectWithValue('Ошибка загрузки списка навыков');
    }
  },
);

const slice = createSlice({
  name: 'skillList',
  initialState,
  reducers: {
    setScrollPosition: (state, action: PayloadAction<number>) => {
      state.scrollPosition = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadSkillList.pending, (state) => {
        state.loading = ELoadingStatus.Loading;
        state.error = undefined;
      })
      .addCase(loadSkillList.fulfilled, (state, action) => {
        const { items, total, loadMore } = action.payload;
        state.page = loadMore ? state.page + 1 : 1;
        state.items = loadMore ? uniqBy([...state.items, ...items], 'id') : items;
        state.total = total;
        state.loading = ELoadingStatus.Succeeded;
      })
      .addCase(loadSkillList.rejected, (state, action) => {
        state.loading = ELoadingStatus.Failed;
        state.error = action.payload as string;
      });
  },
});

const skillList = {
  ...slice.actions,
  selectLoading: (state: RootState) => state.skillList.loading,
  selectItems: (state: RootState) => state.skillList.items,
  selectError: (state: RootState) => state.skillList.error,
  selectTotal: (state: RootState) => state.skillList.total,
  selectScrollPosition: (state: RootState) => state.skillList.scrollPosition,
  loadData: loadSkillList,
};

export const skillListReducer = slice.reducer;
export default skillList;
