import { useEffect, RefObject } from 'react';

function useOutsideClick(
  refs: RefObject<HTMLElement>[],
  callback: (event: MouseEvent | TouchEvent) => void,
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent | TouchEvent) {
      if (refs.every((ref) => ref.current && !ref.current.contains(event.target as Node))) {
        event.stopPropagation();
        callback(event);
      }
    }

    // Bind the event listeners for both web and mobile
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      // Unbind the event listeners on clean up
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [refs, callback]);
}

export default useOutsideClick;
