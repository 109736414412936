import { useMemo, useState } from 'react';

export default function useOpening(timeout: number = 20) {
  const [opening, setOpening] = useState(true);

  useMemo(() => {
    if (opening) {
      setTimeout(() => setOpening(false), timeout);
    }
  }, [opening, timeout]);

  return opening;
}
