import { useCallback, useState } from 'react';
import { QueryParams } from '../types';
import { useLocation, useNavigate } from 'react-router';

const getQueryParams = (search: string): QueryParams => {
  const params = new URLSearchParams(search);
  const queryParams: QueryParams = { asc: false, filters: {} };
  params.forEach((v, k) => {
    if (k === 'page') {
      queryParams.page = Number.parseInt(v);
    } else if (k === 'take') {
      queryParams.take = Number.parseInt(v);
    } else if (k === 'order') {
      queryParams.order = v;
    } else if (k === 'asc') {
      queryParams.asc = v === 'true';
    } else {
      queryParams.filters[k] = v;
    }
  });
  return queryParams;
};

export default function useQueryParams(): [QueryParams, (queryParams: QueryParams) => void] {
  const location = useLocation();
  const [queryParams, setQueryParams] = useState<QueryParams>(getQueryParams(location.search));
  const navigate = useNavigate();

  const setParams = useCallback(
    (params: QueryParams) => {
      const searchParams = new URLSearchParams(params.filters);
      if (params.page) {
        searchParams.append('page', params.page.toString());
      }
      if (params.take) {
        searchParams.append('take', params.take.toString());
      }
      if (params.order) {
        searchParams.append('order', params.order.toString());
        searchParams.append('asc', params.asc.toString());
      }
      setQueryParams(params);
      navigate('?' + searchParams.toString());
    },
    [navigate],
  );
  return [queryParams, setParams];
}
