/**
 * Запрос на отслеживание должен быть сделан только один раз. Apple рекомендует
 * запрашивать разрешение только когда это действительно необходимо для функционирования приложения.
 *
 * Статус отслеживания может быть одним из следующих: 'authorized', 'denied', 'notDetermined' или 'restricted'.
 */
import React, { useCallback, useEffect, useState } from 'react';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
import { Capacitor } from '@capacitor/core';

import { PopupPortal } from '@components/popup-portal/popup-portal';
import Popup from '@components/popup/popup';
import { useDispatch, useSelector } from 'react-redux';
import storage from '../storage';

interface IAppTrackingTransparencyProviderProps {
  children: React.ReactNode;
}

export const AppTrackingTransparencyProvider: React.FC<IAppTrackingTransparencyProviderProps> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const [isAgree, setIsAgree] = useState<boolean>(false);
  const isNative = Capacitor.isNativePlatform();
  const oneTimeShowPopup = useSelector(storage.interactive.selectIsAppTrackingTransparencyAgree);

  const requestTrackingPermission = useCallback(async () => {
    try {
      const response = await AppTrackingTransparency.requestPermission();
      console.log('Статус отслеживания:', response.status);

      if (response.status === 'authorized') {
        // Пользователь разрешил отслеживание
        setIsAgree(true);
      } else {
        setIsAgree(false);
        // Пользователь отказал в отслеживании или статус другой
      }
    } catch (error) {
      console.error('Ошибка при запросе разрешения:', error);
    }
  }, []);

  useEffect(() => {
    if (isNative) {
      requestTrackingPermission();
      dispatch(storage.interactive.setIsAppTrackingTransparencyAgree(true));
    }
  }, []);

  return (
    <>
      {isAgree && !oneTimeShowPopup && (
        <PopupPortal>
          <Popup
            text={
              'Спасибо за ваш выбор! Мы будем использовать данные с заботой о вашей конфиденциальности.'
            }
            showTime={3}
          />
        </PopupPortal>
      )}
      {children}
    </>
  );
};
