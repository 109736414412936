import { ApiResponse } from '@http/types';
import { apiClients } from '@api/index';
import { IUserItem, IEmployee, IReference } from '@models/user-item';
import {
  EPrivacySettingsName,
  EGender,
  EEducationalInstitutionType,
  ELoadingStatus,
} from '@http/enums';

export interface IProfileResponse extends IUserItem, ApiResponse {}

export interface IProfileUserBlocklistItem {
  id: string;
  nickName: string;
  avatarUrl: string;
  isGuru: boolean;
}

export interface IProfileUserBlocklist extends ApiResponse {
  items?: IProfileUserBlocklistItem[];
  total?: number;
  loading?: ELoadingStatus;
}

export interface UpdateProfileRequest {
  userName?: string;
  about?: string;
  avatarId?: number;
  employee?: IEmployee;
  fistName?: string;
  lastName?: string;
  fatherName?: string;
  sharingPermissionsProfile?: EPrivacySettingsName;
  sharingPermissionsProject?: EPrivacySettingsName;
}

export interface UpdatePersonalDataRequest {
  birthday?: Date;
  classLitera?: string;
  classParallel?: string;
  countryCode?: string;
  countryName?: string;
  districtCode?: string;
  districtName?: string;
  educationalInstitution?: string;
  educationalInstitutionId?: number;
  educationalInstitutionType?: EEducationalInstitutionType;
  fatherName?: string;
  firstName?: string;
  gender?: EGender;
  groupNumber?: string;
  lastName?: string;
  localityCode?: string;
  localityName?: string;
  regionCode?: string;
  regionName?: string;
  schoolCode?: string;
  schoolName?: string;
  speciality?: string;
}

export const profile = {
  get: async () => {
    const response = await apiClients.default.get<IProfileResponse>('v1/profile');

    if (response.id) {
      localStorage.setItem('userId', response.id);
    }

    if (response.userName) {
      localStorage.setItem('userName', response.userName);
    }

    return response;
  },
  put: async (request: UpdateProfileRequest) => {
    return apiClients.default.put<IProfileResponse>('v1/profile', request);
  },
  reference: {
    post: async (request: IReference) => {
      return apiClients.default.post<ApiResponse>('v1/profile/reference', request);
    },
    clear: {
      post: async (request: { providerName: string }) => {
        return apiClients.default.post<ApiResponse>('v1/profile/reference/clear', request);
      },
    },
  },
  blocklist: {
    get: async () => {
      return apiClients.default.get<IProfileUserBlocklist>('v1/profile/blocklist');
    },
  },
  personal: {
    put: async (request: UpdatePersonalDataRequest) => {
      return apiClients.default.put<IProfileResponse>('v1/profile/personal', request);
    },
  },
};
