import { apiClients } from '@api/index';
import { ListApiResponse } from '../../../types';
import { IRubricItem } from '@models/rubric-item';

export interface RubricListResponse extends ListApiResponse<IRubricItem> {}

export const rubrics = {
  get: async () => {
    return await apiClients.default.get<RubricListResponse>('v1/rubric');
  },
};
