export interface IFormFile {
  readonly name: string;
  readonly contentType: string;
  readonly blob: Blob;
}

export default class FormFile implements IFormFile{
  readonly name: string;
  readonly contentType: string;
  readonly blob: Blob;

  /**
   * Создать файл формы
   * @param blob - BLOB файла
   * @param name - Имя файла
   */
  constructor(blob: Blob, name: string) {
    this.name = name
    this.contentType = blob.type
    this.blob = blob;
  }
}
