import { useEffect, useContext } from 'react';
import { SchemeContext } from '@providers/SchemeProvider';

export default function useTitleDescription() {
  const { title } = useContext(SchemeContext);

  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);

  return null;
}
