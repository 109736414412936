import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { NewsItem, NewsResponse } from '@api/v0_1/news/news';
import { v0_1 } from '@api/v0_1';
import { ELoadingStatus } from '../../http/enums';

interface INewsState {
  loading: ELoadingStatus;
  error?: string;
  data: NewsItem;
}

const initialState: { [key: number]: INewsState } = {};

const createState = (): INewsState => ({
  loading: ELoadingStatus.Idle,
  data: {},
});

export const loadData = createAsyncThunk<
  { id: number; data: NewsItem }, // возвращаем объект с id и данными новости
  number, // входной параметр — id новости
  { rejectValue: string }
>('newsDetails/loadData', async (id, { rejectWithValue }) => {
  try {
    const response: NewsResponse = await v0_1.news.ID.get(id);
    if (response.errorCode) {
      return rejectWithValue(response.errorMsg!);
    }
    return { id, data: response };
  } catch (error: any) {
    return rejectWithValue(error.message || 'Ошибка загрузки новости');
  }
});

const slice = createSlice({
  name: 'newsDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadData.pending, (state, action) => {
        const id = action.meta.arg;
        state[id] = state[id] ?? createState();
        state[id].loading = ELoadingStatus.Loading;
        state[id].error = undefined;
      })
      .addCase(loadData.fulfilled, (state, action) => {
        const { id, data } = action.payload;
        state[id] = state[id] ?? createState();
        state[id].data = data;
        state[id].loading = ELoadingStatus.Succeeded;
      })
      .addCase(loadData.rejected, (state, action) => {
        const id = action.meta.arg;
        state[id] = state[id] ?? createState();
        state[id].error = action.payload as string;
        state[id].loading = ELoadingStatus.Failed;
      });
  },
});

const newsDetails = {
  ...slice.actions,
  selectError: (id: number) => (state: RootState) => state.newsDetails[id]?.error,
  selectLoading: (id: number) => (state: RootState) => state.newsDetails[id]?.loading,
  selectData: (id: number) => (state: RootState) => state.newsDetails[id]?.data ?? {},
  loadData,
};

export const newsDetailsReducer = slice.reducer;
export default newsDetails;
