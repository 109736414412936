import { batch } from 'react-redux';
import { uniqBy } from 'lodash';
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

import { IProjectItem } from '@http/models/project-item';
import { RootState } from '../store';
import { v1 } from '@api/v1';
import { ELoadingStatus } from '../../http/enums';

interface IUserFavoriteProjectsState {
  error?: string;
  loading: ELoadingStatus;
  items: IProjectItem[];
  total: number;
  page: number;
}

const initialState: IUserFavoriteProjectsState = {
  loading: ELoadingStatus.Idle,
  items: [],
  total: 0,
  page: 1,
};

export const fetchUserFavoriteProjects = createAsyncThunk(
  'userFavoriteProjects/fetchItems',
  async ({ loadMore }: { loadMore?: boolean }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const currentPage = state.userFavoriteProjects.page;

    try {
      const response = await v1.project.get({
        userLiked: true,
        medias: { fetch: true },
        page: loadMore ? currentPage + 1 : 1,
        take: 36,
      });

      if (response.errorCode) {
        throw new Error(response.errorMsg);
      }

      return {
        items: response.items || [],
        total: response.total || 0,
        loadMore,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error instanceof Error ? error.message : 'Unknown error');
    }
  },
);

const slice = createSlice({
  name: 'userFavoriteProjects',
  initialState,
  reducers: {
    deleteItem: (state, action: PayloadAction<number>) => {
      state.items = state.items.filter((item) => item.id !== action.payload);
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserFavoriteProjects.pending, (state, action) => {
        state.loading = ELoadingStatus.Loading;
        state.error = undefined;

        if (!action.meta.arg?.loadMore) {
          state.items = [];
          state.page = 1;
        }
      })
      .addCase(fetchUserFavoriteProjects.fulfilled, (state, action) => {
        const { items, total, loadMore } = action.payload;

        batch(() => {
          if (loadMore) {
            state.items = uniqBy([...state.items, ...items], ({ id }) => id);
            state.page += 1;
          } else {
            state.items = items;
            state.page = 1;
          }
          state.total = total;
          state.loading = ELoadingStatus.Succeeded;
        });
      })
      .addCase(fetchUserFavoriteProjects.rejected, (state, action) => {
        state.loading = ELoadingStatus.Failed;
        state.error = action.payload as string;
      });
  },
});

export const { deleteItem, reset } = slice.actions;

export const userFavoriteProjects = {
  deleteItem,
  reset,
  selectLoading: (state: RootState) => state.userFavoriteProjects.loading,
  selectError: (state: RootState) => state.userFavoriteProjects.error,
  selectItems: (state: RootState) => state.userFavoriteProjects.items,
  selectTotal: (state: RootState) => state.userFavoriteProjects.total,
  loadItems: (loadMore?: boolean) => fetchUserFavoriteProjects({ loadMore }),
};

export const userFavoriteProjectsReducer = slice.reducer;
export default userFavoriteProjects;
