/**
 * Если гостевой режим включен, то показывать welcome-видео на 2х страницах:
 * - главной странице
 * - странице ленты
 * Показывать при первом посещении какой-либо из них.
 * Показывать только один раз либо на той, либо на другой.
 * На других страницах не показывать т.к. пользователь может перейти
 * по прямой ссылке на проект или конкурс.
 */

import { useNavigate } from 'react-router';
import { useEffect } from 'react';

export default function useRedirectToWelcomeVideo(
  isGuestAllowed: boolean,
  registrationIsOpen: boolean,
) {
  const navigate = useNavigate();

  useEffect(() => {
    const notWatched = !localStorage.getItem('is_watched_welcome_video');

    if (notWatched && isGuestAllowed && !registrationIsOpen) {
      navigate('/welcome');
    }
  }, [isGuestAllowed, registrationIsOpen]);

  return null;
}
