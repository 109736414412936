import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ILocation } from '@http/models/location';
import { v1 } from '@api/v1';
import { ELoadingStatus } from '../../http/enums';

interface ILocationListState {
  loading: ELoadingStatus;
  error?: string;
  items: ILocation[];
}

const initialState: ILocationListState = {
  loading: ELoadingStatus.Idle,
  items: [],
};

export const loadData = createAsyncThunk<
  ILocation[], // Возвращаемый тип данных (список локаций)
  void, // Нет аргументов
  { rejectValue: string } // Тип ошибки
>('locationList/loadData', async (_, { rejectWithValue }) => {
  try {
    const response = await v1.organization.location.get();
    if (response.errorCode) {
      return rejectWithValue(response.errorMsg!);
    }
    return response.items;
  } catch (error: any) {
    return rejectWithValue(error.message || 'Ошибка загрузки локаций');
  }
});

const slice = createSlice({
  name: 'locationList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadData.pending, (state) => {
        state.loading = ELoadingStatus.Loading;
        state.error = undefined;
      })
      .addCase(loadData.fulfilled, (state, action: PayloadAction<ILocation[]>) => {
        state.items = action.payload;
        state.loading = ELoadingStatus.Succeeded;
      })
      .addCase(loadData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = ELoadingStatus.Failed;
      });
  },
});

const locationList = {
  selectItems: (state: RootState) => state.locationList.items,
  loadData,
};

export const locationListReducer = slice.reducer;
export default locationList;
