import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { UserAvatarItem } from '@http/api/v0_1/user/user-avatar';
import { v0_1 } from '@api/v0_1';
import { ELoadingStatus } from '../../http/enums';

interface IAvatarListState {
  error?: string;
  loading: ELoadingStatus;
  items: UserAvatarItem[];
  total: number;
}

const initialState: IAvatarListState = {
  loading: ELoadingStatus.Idle,
  items: [],
  total: 0,
};

export const loadData = createAsyncThunk<UserAvatarItem[], void, { rejectValue: string }>(
  'avatarList/loadData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await v0_1.user.avatar.get();

      if (response.errorCode) {
        return rejectWithValue(response.errorMsg!);
      }

      return response;
    } catch (error: any) {
      return rejectWithValue(error.message || 'Ошибка загрузки аватаров');
    }
  },
);

const slice = createSlice({
  name: 'avatarList',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setLoading: (state, action: PayloadAction<ELoadingStatus>) => {
      state.loading = action.payload;
    },
    setItems: (state, action: PayloadAction<UserAvatarItem[]>) => {
      state.items = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadData.pending, (state) => {
        state.loading = ELoadingStatus.Loading;
        state.error = undefined;
      })
      .addCase(loadData.fulfilled, (state, action) => {
        state.items = action.payload;
        state.total = action.payload.length;
        state.loading = ELoadingStatus.Succeeded;
      })
      .addCase(loadData.rejected, (state, action) => {
        state.loading = ELoadingStatus.Failed;
        state.error = action.payload as string;
      });
  },
});

const avatarList = {
  ...slice.actions,
  selectError: (state: RootState) => state.avatarList.error,
  selectLoading: (state: RootState) => state.avatarList.loading,
  selectItems: (state: RootState) => state.avatarList.items,
  selectTotal: (state: RootState) => state.avatarList.total,
  loadData,
};

export const avatarListReducer = slice.reducer;
export default avatarList;
