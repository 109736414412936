import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';

import { IChallengeItem } from '@http/models/challenge-item';
import { IProjectItem } from '@http/models/project-item';
import { IUserItem } from '@http/models/user-item';
import { RootState } from '../store';
import { ELoadingStatus, EProjectSortingOrder } from '@http/enums';
import { addItems as projectAddItems } from './projects';
import { v1 } from '@api/v1';

export type TSearchType = 'challenge' | 'project' | 'user';

interface IGlobalSearch {
  error?: string;
  items: (IChallengeItem | IProjectItem | IUserItem)[] | undefined;
  loading: ELoadingStatus;
  page: number;
  searchPosition: number;
  searchString: string;
  take: number;
  total: number;
  sortingOrder?: string;
  from?: string;
}

const initialState: IGlobalSearch = {
  items: undefined,
  loading: ELoadingStatus.Idle,
  page: 1,
  searchPosition: 0,
  searchString: '',
  take: 20,
  total: 0,
};

export const loadData = createAsyncThunk<
  { items: (IChallengeItem | IProjectItem | IUserItem)[]; total: number; loadmore: boolean },
  { loadDataType: TSearchType; loadmore?: boolean },
  { state: RootState; rejectValue: string }
>(
  'globalSearch/loadData',
  async ({ loadDataType, loadmore = false }, { getState, dispatch, rejectWithValue }) => {
    const state = getState();
    if (!state.globalSearch.searchString.length) {
      return rejectWithValue('Search string is empty');
    }

    const responseObject: any = {
      search: state.globalSearch.searchString,
      skills: { fetch: true },
      medias: { fetch: true },
      page: loadmore ? state.globalSearch.page + 1 : state.globalSearch.page,
      take: loadDataType === 'project' ? 10 : 20,
    };

    if (loadDataType === 'project') {
      responseObject.sortingOrder = EProjectSortingOrder.MostLiked;
      responseObject.hideSecret = true;
    }

    const response = await v1[loadDataType].get(responseObject);

    if (response.errorCode) {
      return rejectWithValue(response.errorMsg!);
    }

    if (loadDataType === 'project') {
      dispatch(projectAddItems(response.items as IProjectItem[]));
    }

    return { items: response.items || [], total: response.total ?? 0, loadmore };
  },
);

const slice = createSlice({
  name: 'globalSearch',
  initialState,
  reducers: {
    setSearchString: (state, action: PayloadAction<string>) => {
      state.searchString = action.payload;
    },
    setFrom: (state, action: PayloadAction<string>) => {
      state.from = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setPosition: (state, action: PayloadAction<number>) => {
      state.searchPosition = action.payload;
    },
    setItems: (
      state,
      action: PayloadAction<(IChallengeItem | IProjectItem | IUserItem)[] | undefined>,
    ) => {
      state.items = uniqBy(
        action.payload?.map((i: any) => ({ ...i, nickname: i.userName })),
        ({ id }) => id,
      );
    },
    reset: () => initialState,
    resetLight: (state) => {
      state.items = undefined;
      state.loading = ELoadingStatus.Idle;
      state.page = 1;
      state.searchPosition = 0;
      state.searchString = '';
      state.take = 20;
      state.total = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadData.pending, (state) => {
        state.loading = ELoadingStatus.Loading;
        state.error = '';
      })
      .addCase(loadData.fulfilled, (state, action) => {
        const { items, total, loadmore } = action.payload;
        if (loadmore) {
          state.page = state.page + 1;
          state.items = state.items ? uniqBy([...state.items, ...items], 'id') : items;
        } else {
          state.page = 1;
          state.items = items;
        }
        state.total = total;
        state.loading = ELoadingStatus.Succeeded;
      })
      .addCase(loadData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = ELoadingStatus.Failed;
      });
  },
});

const { setFrom, setPosition, setPage, setItems, reset, setSearchString, resetLight } =
  slice.actions;

const globalSearch = {
  ...slice.actions,
  resetLight,
  setFrom,
  setPosition,
  reset,
  setPage,
  setSearchString,
  setItems,
  selectTotal: (state: RootState) => state.globalSearch.total,
  selectFrom: (state: RootState) => state.globalSearch.from,
  selectSearchPosition: (state: RootState) => state.globalSearch.searchPosition,
  selectLoading: (state: RootState) => state.globalSearch.loading,
  selectItems: (state: RootState) => state.globalSearch.items,
  selectPage: (state: RootState) => state.globalSearch.page,
  selectSearchString: (state: RootState) => state.globalSearch.searchString,
  loadData,
};

export const globalSearchReducer = slice.reducer;
export default globalSearch;
