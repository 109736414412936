import {
  IOrganizationModuleItem,
  IOrganizationModuleOption,
} from '@api/v1/organization/organization';

// TODO: типизировать options
const getOptionValue = (
  options: Record<string, IOrganizationModuleOption> | undefined,
  path: string,
  defaultValue: any = null,
): any => {
  if (!options) return defaultValue;

  return path.split('.').reduce<any>((acc, part) => acc && acc[part], options) ?? defaultValue;
};

export default function useOptions(options: Record<string, IOrganizationModuleItem>) {
  const registration = options?.Registration?.normalizedOptions as
    | Record<string, IOrganizationModuleOption>
    | undefined;
  const gamification = options?.Gamification?.normalizedOptions as
    | Record<string, IOrganizationModuleOption>
    | undefined;
  const publication = options?.Publication?.normalizedOptions as
    | Record<string, IOrganizationModuleOption>
    | undefined;
  const user = options?.User?.normalizedOptions as
    | Record<string, IOrganizationModuleOption>
    | undefined;
  const authorization = options?.Authorization?.normalizedOptions as
    | Record<string, IOrganizationModuleOption>
    | undefined;
  const crossposting = options?.Crossposting?.normalizedOptions as
    | Record<string, IOrganizationModuleOption>
    | undefined;
  const ui = options?.Ui?.normalizedOptions as
    | Record<string, IOrganizationModuleOption>
    | undefined;
  const video = options?.Video?.normalizedOptions as
    | Record<string, IOrganizationModuleOption>
    | undefined;
  const support = options?.Support?.normalizedOptions as
    | Record<string, IOrganizationModuleOption>
    | undefined;

  return {
    // REGISTRATION
    registrationType: getOptionValue(registration, 'Registration_Type.value'),
    isSmsRegistration: getOptionValue(registration, 'Registration_Phone.value'),
    isEmailRegistration: getOptionValue(registration, 'Registration_Email.value'),
    isVkRegistration: getOptionValue(registration, 'Registration_Vk.value'),
    isReferralRegistration: getOptionValue(registration, 'Registration_Referral.value'),
    registrationReferralType: getOptionValue(registration, 'Registration_Referral_Type.value'),
    registrationReferralPoints: getOptionValue(registration, 'Registration_Referral_Points.value'),
    // Gamification
    shopId: getOptionValue(gamification, 'Gamification_Shop_Id.value'),
    showShop:
      getOptionValue(gamification, 'Gamification_Shop.value') &&
      Boolean(getOptionValue(gamification, 'Gamification_Shop_Id.value')) &&
      Number(getOptionValue(gamification, 'Gamification_Shop_Id.value')) > 0,
    shopEmail: getOptionValue(gamification, 'Gamification_ShopEmail.value'),
    shopVKCommunityLink: getOptionValue(gamification, 'Gamification_ShopVkCommunity.value'),
    wantToBuyButtonType: getOptionValue(gamification, 'Gamification_WantToBuyButton.value'),
    showCoins: getOptionValue(gamification, 'Gamification_Shop.value'),
    canRepeatChallenge: getOptionValue(gamification, 'Gamification_Challenge_Repetition.value'),
    expRepeatChallenge: getOptionValue(gamification, 'Gamification_Challenge_Repetition_Exp.value'),
    isShowCalendar: getOptionValue(gamification, 'Gamification_Calendar_Event.value'),
    ratingLocation: getOptionValue(gamification, 'Gamification_Rating_Location.value'),
    ratingSubdivision: getOptionValue(gamification, 'Gamification_Rating_Subdivision.value'),
    ratingUniversity: getOptionValue(gamification, 'Gamification_Rating_University.value'),
    ratingCollege: getOptionValue(gamification, 'Gamification_Rating_College.value'),
    ratingSchool: getOptionValue(gamification, 'Gamification_Rating_School.value'),
    ratingTop: getOptionValue(gamification, 'Gamification_Rating_Top.value'),
    isProjectWithoutChallenge: getOptionValue(
      gamification,
      'Gamification_Project_Challengeless.value',
    ),
    coinsProjectWithoutChallenge: getOptionValue(
      gamification,
      'Gamification_Project_Challengeless_Coins.value',
    ),
    expProjectWithoutChallenge: getOptionValue(
      gamification,
      'Gamification_Project_Challengeless_Exp.value',
    ),
    // Publication
    showSubscribeNewsButton: getOptionValue(publication, 'Publication_Festival_News.value'),
    showAttachPresentation: getOptionValue(publication, 'Publication_ProjectFile.value'),
    // USER
    isEmailUser: getOptionValue(user, 'User_Profile_Email.value'),
    isPhoneUser: getOptionValue(user, 'User_Profile_Phone.value'),
    isUserProfileSocial: getOptionValue(user, 'User_Profile_Social.value'),
    isUserProfilePersonalData: getOptionValue(user, 'User_Profile_Personal_Data.value'),
    isNicknameEmailSms:
      Boolean(getOptionValue(user, 'User_Profile_Email.value')) &&
      Boolean(getOptionValue(user, 'User_Profile_Phone.value')),
    isNicknameEmail:
      Boolean(getOptionValue(user, 'User_Profile_Email.value')) &&
      !Boolean(getOptionValue(user, 'User_Profile_Phone.value')),
    isNicknameSms:
      !Boolean(getOptionValue(user, 'User_Profile_Email.value')) &&
      Boolean(getOptionValue(user, 'User_Profile_Phone.value')),
    isNickname:
      !Boolean(getOptionValue(user, 'User_Profile_Email.value')) &&
      !Boolean(getOptionValue(user, 'User_Profile_Phone.value')),
    additionFields: getOptionValue(user, 'User_Profile_AdditionFields.values'),
    requiredInviteStudentFields: getOptionValue(
      user,
      'User_Profile_Invite_Required_Student.values',
    ),
    requiredInvitePupilFields: getOptionValue(user, 'User_Profile_Invite_Required_Pupil.values'),
    // Crossposting
    isCrosspostingVk: getOptionValue(crossposting, 'Crossposting_Project_Vk.value'),
    // Authorization
    canResetByEmail: getOptionValue(authorization, 'Authorization_Reset_Password_Email.value'),
    canResetByPhone: getOptionValue(authorization, 'Authorization_Reset_Password_Phone.value'),
    // General
    isGuestAllowed: getOptionValue(authorization, 'Authorization_Required.value'),
    // UI
    authHomeScreenType: getOptionValue(ui, 'Ui_Displaying_HomeScreen.value'),
    // Video
    highUserLevelVideoDuration: getOptionValue(video, 'Video_HighUserLevelVideoDuration.value'),
    mediumUserLevelVideoDuration: getOptionValue(video, 'Video_MediumUserLevelVideoDuration.value'),
    lowUserLevelVideoDuration: getOptionValue(video, 'Video_LowUserLevelVideoDuration.value'),
    // Support
    supportType: getOptionValue(support, 'Support_Provider.value'),
    supportEmail: getOptionValue(support, 'Support_Value.value'),
    projectSupportType: getOptionValue(support, 'SupportProject_Provider.value'),
    projectSupportEmail: getOptionValue(support, 'SupportProject_Value.value'),
  };
}
