import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ESupportType } from '@http/enums';
import { useCarrotQuest } from '@hooks/index';
import { formatTranslation } from '@utils/format-translation';
import { openEmailApp } from '@utils/openEmailApp';
import { openExternalLink } from '@utils/openExternalLink';
import usePopup from './usePopup';
import { copyText } from '@utils/copyText';

export interface IEmailOptions {
  email: string;
  body?: string;
  subject?: string;
}

interface ISupportActionParams {
  type?: ESupportType;
  emailOptions?: IEmailOptions;
  vkLink?: string;
  clipboardText?: string;
}

interface ISupportProps {
  onSupportAction?: () => void;
}

export default function useSupport(props: ISupportProps = {}) {
  const { showPopup } = usePopup();
  const { onSupportAction } = props;

  const { t } = useTranslation();
  const carrotQuest = useCarrotQuest();

  const handleEmailAction = useCallback(
    (options: IEmailOptions) => {
      showPopup({
        title: formatTranslation(t('common__msg_support_email_software_open_web'), [options.email]),
      });
      openEmailApp(options);
      onSupportAction?.();
    },
    [showPopup, onSupportAction, t],
  );

  const handleVkAction = useCallback(
    (link: string) => {
      openExternalLink(link);
      onSupportAction?.();
    },
    [onSupportAction],
  );

  const handleCarrotAction = useCallback(
    async (copyString?: string) => {
      if (copyString) {
        try {
          await copyText(copyString);

          showPopup({
            title: t('common__msg_carrot_support'),
          });
        } catch {
          console.error('useSupport copy error');
        }
      }
      carrotQuest && typeof carrotQuest.open === 'function' && carrotQuest.open();
      onSupportAction?.();
    },
    [carrotQuest, onSupportAction, t],
  );

  const handleSupportAction = useCallback(
    (params: ISupportActionParams) => {
      const { type, emailOptions, vkLink, clipboardText } = params;

      if (type === ESupportType.Email && emailOptions) {
        handleEmailAction(emailOptions);
        return;
      }

      if (type === ESupportType.Vk && vkLink) {
        handleVkAction(vkLink);
        return;
      }

      handleCarrotAction(clipboardText);
      return;
    },
    [handleCarrotAction, handleEmailAction, handleVkAction],
  );

  return {
    handleSupportAction,
  };
}
