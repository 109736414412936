import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { batch } from 'react-redux';
import storage from '@storage/index';
import { IProfileUserBlocklistItem, IProfileResponse } from '@api/v1/profile/profile';
import { v1 } from '@api/v1';
import { v0_1 } from '@api/v0_1';
import { ELoadingStatus } from '../../http/enums';

interface IUserProfileState {
  error?: string;
  loading: ELoadingStatus;
  data: IProfileResponse;
}

const initialState: { [nickname: string]: IUserProfileState } = {};

const createState = (): IUserProfileState => ({
  data: {},
  loading: ELoadingStatus.Idle,
});

export const fetchUserProfile = createAsyncThunk(
  'userProfile/fetch',
  async (nickname: string, { getState }) => {
    const state = getState() as RootState;
    const isCurrentUser = nickname === state.identity.nickname;

    const response: IProfileResponse = isCurrentUser
      ? await v1.profile.get()
      : await v1.user.nickname.get(nickname);

    if (response.errorCode) {
      throw new Error(response.errorMsg);
    }

    return { nickname, data: { ...response, isCurrentUser } };
  },
);

export const toggleBlockUser = createAsyncThunk(
  'userProfile/toggleBlock',
  async ({ nickname, id }: { nickname: string; id: string }, { getState }) => {
    const state = getState() as RootState;
    const currentBlockStatus = state.userProfile[nickname]?.data.inBlocklist;

    const response = currentBlockStatus
      ? await v1.user.blocklist.delete(id)
      : await v1.user.blocklist.post(id);

    if (response.errorCode) {
      throw new Error(response.errorMsg);
    }

    return { nickname, newStatus: !currentBlockStatus };
  },
);

export const removeFromBlocklist = createAsyncThunk(
  'userProfile/removeFromBlocklist',
  async (item: IProfileUserBlocklistItem) => {
    const response = await v1.user.blocklist.delete(item.id);

    if (response.errorCode) {
      throw new Error(response.errorMsg);
    }

    return item.nickName;
  },
);

export const toggleSubscribe = createAsyncThunk(
  'userProfile/toggleSubscribe',
  async ({ nickname, id }: { nickname: string; id: string }, { getState }) => {
    const state = getState() as RootState;
    const currentSubscribeStatus = state.userProfile[nickname]?.data.subscription;

    const response = currentSubscribeStatus
      ? await v0_1.following.delete(id)
      : await v0_1.following.post(id);

    if (response.errorCode) {
      throw new Error(response.errorMsg);
    }

    return { nickname, newStatus: !currentSubscribeStatus };
  },
);

const slice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.pending, (state, action) => {
        const nickname = action.meta.arg;
        state[nickname] = state[nickname] ?? createState();
        state[nickname].loading = ELoadingStatus.Loading;
        state[nickname].error = undefined;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        const { nickname, data } = action.payload;
        state[nickname].data = data;
        state[nickname].loading = ELoadingStatus.Succeeded;
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        const nickname = action.meta.arg;
        state[nickname].error = action.error.message;
        state[nickname].loading = ELoadingStatus.Failed;
      })
      .addCase(toggleBlockUser.fulfilled, (state, action) => {
        const { nickname, newStatus } = action.payload;
        if (state[nickname]) {
          state[nickname].data.inBlocklist = newStatus;
        }
      })
      .addCase(removeFromBlocklist.fulfilled, (state, action) => {
        const nickname = action.payload;
        batch(() => {
          if (state[nickname]) {
            state[nickname].data.inBlocklist = false;
          }
          storage.profile.removeUserFromBlocklist(nickname);
        });
      })
      .addCase(toggleSubscribe.fulfilled, (state, action) => {
        const { nickname, newStatus } = action.payload;
        if (state[nickname]) {
          state[nickname].data.subscription = newStatus;
        }
      });
  },
});

const userProfile = {
  ...slice.actions,
  selectLoading: (nickname: string) => (state: RootState) => state.userProfile[nickname]?.loading,
  selectError: (nickname: string) => (state: RootState) => state.userProfile[nickname]?.error,
  selectData: (nickname: string) => (state: RootState) => state.userProfile[nickname]?.data ?? {},
  fetchUserProfile,
  toggleBlockUser,
  removeFromBlocklist,
  toggleSubscribe,
};

export const userProfileReducer = slice.reducer;
export default userProfile;
