import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import storage from '@storage/index';

export default function useHiddenNavigation() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(storage.navigation.setHidden(true));

    return () => {
      dispatch(storage.navigation.setHidden(false));
    };
  }, []);
}
